<script>
import _ from "lodash";
import NextArrowIcon from "@/assets/img/next-arrow.svg";

export default {
  name: "MFTPO",
  props: {
    title: {
      type: String,
      required: false
    },
    options: {
      type: Array,
      required: true
    },
    answerCols: {
      type: Array,
      required: true
    },
    handleChange: {
      type: Function,
      required: true
    },
    optionSelected: {
      type: String,
      required: false,
      default: []
    },
    disabled: {
      type: Boolean,
      required: false
    },
    currentQuestion: {
      type: Boolean,
      required: false,
      default: false
    },
    isMainQuestion: {
      type: Boolean,
      required: false,
      default: false
    },
    isAiGenerated: {
      type: Boolean,
      required: false,
      default: false
    },
    previousAnswer: {
      type: Object,
      required: false
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    revisitingSegment: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data(){
    return {
      answerCols1: [{
        label: "Yearly Spend ($M)",
        type: "text"
      }, {
        label: "Average Size ($M)",
        type: "num"
      }],
      readOnly: !_.isEmpty(this.previousAnswer?.selection),
      list: _.map(this.options, (v, i) => {
        let prevFt = null;
        if (this.previousAnswer) {
          const indexOfOptionInSelection = _.indexOf(this.previousAnswer.selection, v);
          if (indexOfOptionInSelection > -1){
            prevFt = this.previousAnswer.optionNumber[indexOfOptionInSelection]
          }
        }
        return {
          name: v,
          id: i,
          ft: prevFt ? prevFt : []
        }
      }),
      showSubmitButton: false,
      NextArrowIcon,
      _: _
    }
  },
  methods: {
    handleAnswer(){
      const labels = _.map(this.list, v => v.name);
      const optionSelection = _.map(this.list, v => v.ft);
      this.handleChange(labels, optionSelection);
      this.showSubmitButton = false;
    },
    handleTextAreaEdit(){
      if (this.allOptionSelected){
        this.showSubmitButton = true;
      }
    }
  },
  computed: {
    allOptionSelected(){
      return _.every(_.map(this.list, v => {
        return !_.isEmpty(v.ft) && _.size(_.filter(v.ft, v1 => `${v1}`.length > 0)) === _.size(this.answerCols)
      }), v => v)
    }
  },
  watch: {
    allOptionSelected(newValue){
      this.showSubmitButton = !!newValue;
    },
    currentQuestion(newValue){
      if (!newValue){
        this.showSubmitButton = false;
      }
    }
  },
}
</script>

<template>
  <div class="ftpo-container">
    <div>
      <div :class="`grid w-[900px] mb-1 gap-6`" :style="`grid-template-columns: repeat(${_.size(answerCols)+2}, minmax(0, 1fr));`">
        <div class="col-span-2"></div>
        <div v-for="col in answerCols" :key="col.label">{{col.label}}</div>
      </div>
      <div v-for="(opt, index) in list" :key="opt.name" :class="`grid w-[900px] mb-1 gap-6`" :style="`grid-template-columns: repeat(${_.size(answerCols)+2}, minmax(0, 1fr));`">
        <div class="col-span-2 text-left pl-3" style="background: #F5F5F5; line-height: 40px; border: 1px solid #787878">{{ opt.name }}:</div>
        <div class="ftp-free-text-input" v-for="(col, colIndex) in answerCols" :key="col.label">
          <input
              :type="col.type"
              :ref="`ftpo${index}`"
              class="ftp-free-text-area"
              :disabled="readOnly"
              v-model="opt.ft[colIndex]"
              v-on:keyup="()=>{
                handleTextAreaEdit()
              }"
          />
        </div>
      </div>
    </div>
    <div class="ftpo-submit-button-container">
      <button class="app-submit-button" v-if="!readOnly && showSubmitButton" @click="()=>{
        handleAnswer()
      }"><img style="margin: 0 auto" :src="NextArrowIcon" alt=""></button>
    </div>
  </div>
</template>

<style scoped>
.ftpo-container{
  display: flex;
  flex-direction: column;
  width: 600px;
}
.ftpo-submit-button-container{
  align-self: flex-start;
  margin-top: 10px;
}
.ftpo-opt-container{
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-bottom: 20px;
  justify-content: space-between;
  width: 900px;
}
.ftp-free-text-input{
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.ftp-free-text-area {
  width: 595px;
  height: 51px;
  border: 1px solid #D7D7D7;
  border-radius: 3px;
  text-overflow: ellipsis;
  padding-left: 10px;
  outline: none;
  resize: none;
}

.ftp-free-text-input-submit-button {
  border: 1px solid #D7D7D7;
  border-radius: 3px;
  background: #9B9B9B;
  height: 51px;
  padding: 10px;
}
</style>