<script>
import _ from "lodash";

import getStatus from "@/services/survey/getStatus";
import getSegment from "@/services/survey/getSegment";
import getPillarSegmentCompletedData from "@/services/survey/getPillarSegmentCompletedData";
import postSegment from "@/services/survey/postSegment";
import getGenAIQuestion from "@/services/survey/getGenAIQuestion";
import skipSegment from "@/services/survey/skipSegment";
import Loader from "@/components/Loader.vue";
import Question from "@/components/Question.vue";
import postGenAIAnswer from "@/services/survey/postGenAIAnswer";

import SurveyCompleted from "@/components/Survey/SurveyCompleted.vue";

const initialStatus = {
  showLoader: false,
  questionsSubmitting: false,
  loading: false,
  pillarsInfo: null,
  pillarName: "",
  pillarId: "",
  segmentId: "",
  surveyCompleted: false,
  staticQuestions: [],
  currentQuestionAnswer: [],
  segmentFinished: false,
  revisitingSegment: false,
  readOnly: false,
  readOnlySegmentSkipped: false,

  conditionsByLevel: [],

  loadingAIQuestion: false,
}

const getNextPillarSegmentInfo = (pillarsInfo, pillarId, segmentId) => { // Extracts next in line Segment Info as per currently set Pillar ID and Segment ID; First segment if segment ID not set
  let pillarIdToFetch = null;
  let segmentIdToFetch = null;
  const currentPillarInfo = _.find(pillarsInfo, v => v.pillarId === pillarId);
  const currentPillarIndex = _.findIndex(pillarsInfo, v => v.pillarId === pillarId);
  const currentPillarSegments = _.get(currentPillarInfo, "segments");
  let currentSegmentIndex = -1;
  if (segmentId || segmentId === 0){
    currentSegmentIndex = _.findIndex(currentPillarSegments, v => `${v.id}` === `${segmentId}`);
  }
  if (currentSegmentIndex === _.size(currentPillarSegments)-1){
    // This was the last Segment of the current Pillar
    const nextPillarInfo = pillarsInfo[currentPillarIndex+1];
    if (!nextPillarInfo){
      // Iteration has reached the last Segment in the entire Pillars Info
      // Return Null to indicate this was the last Segment in the entire questionnaire
      return null;
    }

    // Check if in the next Pillar has any unanswered segments which is also not skipped
    // If such a case, then we cannot revisit the pillar
    // const allSegmentsInPillar = _.get(nextPillarInfo, "segments");
    // const answeredOrSkippedSegments = _.filter(allSegmentsInPillar, v => `${v.status}` === "1" || `${v.is_skipped}` === "1");
    // if (_.size(answeredOrSkippedSegments) !== _.size(allSegmentsInPillar)){
    //   // Cannot revisit the Pillar as it has unanswered Segments
    //   // Return false to indiate stop revisit and continue the answer mode.
    //   return false;
    // }
    pillarIdToFetch = nextPillarInfo.pillarId;
    segmentIdToFetch = _.get(nextPillarInfo, "segments[0].id");

  } else {
    pillarIdToFetch = currentPillarInfo.pillarId;
    segmentIdToFetch = _.get(currentPillarInfo, `segments[${currentSegmentIndex+1}].id`);
  }
  const pillarToFetchInfo = _.find(pillarsInfo, v => `${v.pillarId}` === `${pillarIdToFetch}`);
  const segmentToFetchInfo = _.find(_.get(pillarToFetchInfo, "segments"), v => `${v.id}` === `${segmentIdToFetch}`);

  return {
    pillarToFetchInfo,
    segmentToFetchInfo
  }
}

const getLevelisedQuestions = (questions) => {
  let levelisedQuestions = [];
  let currentLevel = 0;
  let prevParentIds = [];
  while(true){
    _.forEach(questions, v => {
      if (!levelisedQuestions[currentLevel]) levelisedQuestions[currentLevel] = [];
      if (currentLevel === 0){
        if (_.isEmpty(v.parent)) levelisedQuestions[currentLevel].push(v);
      } else {
        if (!_.isEmpty(_.intersection(prevParentIds, v.parent))){
          levelisedQuestions[currentLevel].push(v);
        }
      }
    });
    const newParentIds = _.map(levelisedQuestions[currentLevel], v => v.id);
    const toRemoveParentIdsFromPreviousLevel = _.intersection(prevParentIds, newParentIds);
    if (_.size(toRemoveParentIdsFromPreviousLevel) > 0) {
      levelisedQuestions[currentLevel - 1] = _.filter(levelisedQuestions[currentLevel - 1], v => !_.includes(toRemoveParentIdsFromPreviousLevel, v.id))
    }
    if (_.isEmpty(levelisedQuestions[currentLevel])) {
      levelisedQuestions = _.slice(levelisedQuestions, 0, currentLevel);
      break;
    }
    prevParentIds = newParentIds;
    currentLevel++;
  }
  return levelisedQuestions;
}

const isConditionsValidForQuestion = (question, conditionsByLevel) => {
  const conditions = _.reduce(conditionsByLevel, (acc, v)=> ({...acc, ...v}), {})
  const validParents = _.keys(conditions);
  const matchedParentsIds = _.intersection(question.parent, validParents);

  for (let i = 0; i < _.size(matchedParentsIds); i++){
    const parentId = matchedParentsIds[i];
    const questionOptions = question.parentOptions[parentId];
    const conditionOptions = conditions[parentId];
    const matchedOptions = _.intersection(questionOptions, conditionOptions);
    if (!_.isEmpty(matchedOptions)){
      return true;
    }
  }
  return false;
}

export default {
  name: "Survey",
  components: {Question, Loader, SurveyCompleted},
  props: {
    setNavMenuItems: {
      type: Function,
      required: true
    },
    selectedNavItem: {
      type: String,
      required: true
    },
    handleProgressPercent: {
      type: Function,
      required: true
    }
  },
  data(){
    return {
      ...initialStatus,
      progressPercent: 0,
      _: _
    }
  },
  methods: {
    setNavMenu(){
      this.setNavMenuItems({
        items: _.map(this.pillarsInfo, v => {
          const numberOfSegments = _.size(v.segments);
          const pending = _.filter(v.segments, s => s.status === 0 || s.is_skipped === 1);
          const completed = numberOfSegments - _.size(pending);
          const completion = _.round((completed / numberOfSegments)*100);
          return {
            title: v.pillarName,
            completion: completion
          }
        }),
        selected: _.findIndex(this.pillarsInfo, v => v.pillarId === this.pillarId)
      });
    },
    async loadPillarInfo(){
      this.showLoader = true;
      return await getStatus().then(async pillarsInfo =>{
        if (pillarsInfo.error){
          this.pillarsInfo = null;
          throw new Error(pillarsInfo.errorMessag);
        } else {
          this.pillarsInfo = pillarsInfo;
        }
        return true;
      }).catch(e=> {
        console.error(e);
        return false;
      }).finally(()=>{
        this.showLoader = false;
      });
    },
    resetAllExcept(exceptList = []){
      _.forEach(_.keys(initialStatus).filter(v => _.indexOf(exceptList, v) === -1), (key) => {
        this[key] = initialStatus[key];
      })
    },
    async loadNextSegment(loadAsSequence = false){ // Loads next segment this has not been answered yet
      this.showLoader = true;
      return await this.loadPillarInfo().then(async (isPillarInfoLoaded)=>{
        this.calculateProgressPercent();

        if (isPillarInfoLoaded){
          this.showLoader = true;
          const nextInfo = getNextPillarSegmentInfo(this.pillarsInfo, this.pillarId, this.segmentId);

          if (nextInfo === null){
            this.setNavMenu();
            this.surveyCompleted = true;
            return;
          }
          const {pillarToFetchInfo, segmentToFetchInfo} = nextInfo;
          this.surveyCompleted = false;
          let pillarId = null, segmentId = null;
          if (loadAsSequence){
            pillarId = pillarToFetchInfo.pillarId;
            segmentId = segmentToFetchInfo.id;
          }
          await getSegment(pillarId, segmentId).then(async (currentSegmentRes)=>{
            if (!currentSegmentRes || currentSegmentRes.error){
              console.error(currentSegmentRes.errorMessage);
              return false;
            }
            this.resetAllExcept(["pillarsInfo"]);
            this.pillarId = currentSegmentRes.pillarId;
            this.setNavMenu();
            if (!currentSegmentRes.pillarId) {
              this.surveyCompleted = true;
              return true;
            } else {
              const segmentQuestions = currentSegmentRes.questions;
              this.pillarName = _.get(_.find(this.pillarsInfo, v => v.pillarId === currentSegmentRes.pillarId), "pillarName");
              this.segmentId = currentSegmentRes.segmentId;
              this.staticQuestions = getLevelisedQuestions(segmentQuestions);


              this.currentQuestionAnswer = [];
              await new Promise(resolve => {
                setTimeout(()=>{
                  resolve();
                }, 100);
              });
              if (currentSegmentRes.status === 1){
                this.readOnlySegmentSkipped = true;
                this.readOnly = true;
                this.currentQuestionAnswer = segmentQuestions;
                this.revisitingSegment = true;
              } else {
                this.readOnlySegmentSkipped = false;
                const firstQuestion = _.first(segmentQuestions);
                this.revisitingSegment = false;

                this.currentQuestionAnswer = [{
                  ...firstQuestion,
                  parent: [],
                  parentOptions: [],
                  staticQuestionIndex: 0,
                  questionLevel: 0,
                  questionLevelIndex: 0,
                  isAiGenerated: false
                }];
              }
              return true;
            }
          }).catch(e=>{
            console.error(e);
            this.showLoader = false;
            return false;
          }).finally(()=>{
            this.showLoader = false;
          });
        } else {
          return false;
        }
      }).catch((e)=> {
        console.error(e);
        return false;
      }).finally(()=>{
        this.showLoader = false;
      });
    },
    async loadReadOnlySegment(){ // Loads asked Pillar's Segment that has been answered before
      this.showLoader = true;
      this.loading = true;
      return await this.loadPillarInfo().then(async (isPillarInfoLoaded)=>{
        if (isPillarInfoLoaded){
          const {pillarToFetchInfo, segmentToFetchInfo} = getNextPillarSegmentInfo(this.pillarsInfo, this.pillarId, this.segmentId);
          if (!pillarToFetchInfo || !segmentToFetchInfo){
            return await this.loadNextSegment();
          }

          this.resetAllExcept(["pillarsInfo"]);
          this.pillarId = pillarToFetchInfo.pillarId;
          this.setNavMenu();
          this.pillarName = _.get(_.find(this.pillarsInfo, v => v.pillarId === pillarToFetchInfo.pillarId), "pillarName");
          this.segmentId = segmentToFetchInfo.id;
          this.segmentFinished = true;
          this.revisitingSegment = true;
          this.readOnly = true;

          if (segmentToFetchInfo.is_skipped){
            this.readOnlySegmentSkipped = true;
            return true;
          } else {
            this.readOnlySegmentSkipped = false;
          }
          this.showLoader = true;
          this.loading = true;
          const data = await getPillarSegmentCompletedData({
            pillarId: pillarToFetchInfo.pillarId,
            segmentId: segmentToFetchInfo.id
          }).catch(e=>{
            console.error(e);
            this.showLoader = false;
            return false;
          });

          if (data && data.pillarId){
            this.staticQuestions = getLevelisedQuestions(_.filter(data.questions, v => !v.isAiGenerated));

            this.currentQuestionAnswer = [];
            await new Promise(resolve => {
              setTimeout(()=>{
                resolve();
              }, 100);
            });
            this.currentQuestionAnswer = data.questions;
            return true;
          }

          this.showLoader = false;
          this.loading = false;
        } else {
          return false;
        }
      }).catch((e)=> {
        console.error(e);
        return false;
      }).finally(()=>{
        this.showLoader = false;
        this.loading = false;
      });
    },

    nextStaticQuestion(currentLevel, currentQuestionLevelIndex){
      for (let indexInCurrentLevel = currentQuestionLevelIndex + 1; indexInCurrentLevel < _.size(this.staticQuestions[currentLevel]); indexInCurrentLevel++){
        const currentLevelQuestion = this.staticQuestions[currentLevel][indexInCurrentLevel];

        if (isConditionsValidForQuestion(currentLevelQuestion, this.conditionsByLevel)){
          return {...currentLevelQuestion, questionLevel: currentLevel, questionLevelIndex: indexInCurrentLevel};
        }
      }
      // No more question in current level, move to next level
      if (currentLevel < _.size(this.staticQuestions)) {
        return this.nextStaticQuestion(++currentLevel, -1);
      } else {
        return null;
      }
    },
    async submitAnswers(isLastQuestionAIGenerated){
      this.questionsSubmitting = true;
      const questionAnswer = JSON.stringify(this.currentQuestionAnswer);
      let apiFunc = isLastQuestionAIGenerated ? postGenAIAnswer : postSegment
      const nextQ = await apiFunc({
        pillarId: this.pillarId,
        segmentId: this.segmentId,
        questionAnswer: questionAnswer
      });
      this.questionsSubmitting = false;
      return nextQ;
    },
    async nextAIQuestion(){
      this.questionsSubmitting = true;
      this.loadingAIQuestion = true;
      const genAIQRes = await getGenAIQuestion({
        pillarId: this.pillarId,
        segmentId: this.segmentId
      });
      this.questionsSubmitting = false;
      this.loadingAIQuestion = false;
      return {
        id: `${_.size(this.currentQuestionAnswer)}`,
        title: genAIQRes.question,
        options: genAIQRes.options,
        type: genAIQRes.type,
        isAiGenerated: true
      }
    },
    async handleAnswer(questionIndex, selection, optionNumbers, otherOptions = {}){
      this.currentQuestionAnswer[questionIndex].answer = {
        selection,
        optionNumber: optionNumbers
      };
      if (!(optionNumbers instanceof Array)){
        optionNumbers = [optionNumbers]
      }

      this.currentQuestionAnswer = this.currentQuestionAnswer.slice(0, questionIndex+1);
      if (otherOptions.onlyTouch) return;

      let nextQuestion = null;
      if (!this.currentQuestionAnswer[questionIndex].isAiGenerated){
        const currentLevel = this.currentQuestionAnswer[questionIndex].questionLevel;
        _.set(this.conditionsByLevel, `[${currentLevel}][${this.currentQuestionAnswer[questionIndex].id}]`, optionNumbers)

        this.conditionsByLevel = this.conditionsByLevel.slice(0, currentLevel+1);

        nextQuestion = this.nextStaticQuestion(currentLevel, this.currentQuestionAnswer[questionIndex].questionLevelIndex);
      }

      if (nextQuestion === null){
        // Static question finished, submit Segment
        const nextQ = await this.submitAnswers(this.currentQuestionAnswer[questionIndex].isAiGenerated);
        if (nextQ === "GEN_AI"){
          nextQuestion = await this.nextAIQuestion();
        }
      }
      if (nextQuestion){
        this.currentQuestionAnswer.push({
          ...nextQuestion,
          answer: null,
          isAiGenerated: !!nextQuestion.isAiGenerated
        })
      } else {
        // Segment finished
        // this.segmentFinished = true;
        await this.loadNextSegment(true);
      }
    },
    async handleSkipSegment(){
      this.showLoader = true;
      await skipSegment({
        pillarId: this.pillarId,
        segmentId: this.segmentId
      }).then(async (res)=>{
        if (res){
          await this.loadNextSegment(true)
        }
      }).catch(e=>console.error(e)).finally(()=>{
        this.showLoader = false;
      });
    },

    calculateProgressPercent(){
      if (this.pillarsInfo){
        const {totalQuestion, totalAnswered} = _.reduce(this.pillarsInfo, (acc, v)=> {
          const {pillarTotalQuestion, pillarQuestionAnswerd} = _.reduce(_.get(v, "segments"), (acc1, v1)=>{
            const questionsInSegment = _.toNumber(_.get(v1, "static_questions")) + 1 + 1;
            acc1.pillarTotalQuestion += questionsInSegment;
            if (`${v1.status}` === "1"){
              acc1.pillarQuestionAnswerd += questionsInSegment;
            }
            return acc1;
          }, {
            pillarTotalQuestion: 0,
            pillarQuestionAnswerd: 0
          });

          acc.totalQuestion += pillarTotalQuestion;
          acc.totalAnswered += pillarQuestionAnswerd;
          return acc;
        }, {
          totalQuestion: 0,
          totalAnswered: 0
        })

        // let currentAnswered = 0;
        // if (isCurrentQAToCover){
        //   currentAnswered = _.reduce(this.currentQuestionAnswer, (acc, v)=>{
        //     if (v.answer || v.answer === 0){
        //       acc += 1;
        //     }
        //     return acc;
        //   }, 0)
        // }
        this.progressPercent = _.round(_.toNumber((totalAnswered / totalQuestion)*100));
      } else {
        this.progressPercent = null;
      }

      if (_.toNumber(this.progressPercent) > 100){
        this.progressPercent = 100;
      }
      this.handleProgressPercent(this.progressPercent);
    },
    async handleNavigatePillarFromCompletionScreen(pillarName){
      const pillarId = _.get(_.find(this.pillarsInfo, v => v.pillarName === pillarName), "pillarId");
      this.pillarId = pillarId;
      this.segmentId = null;
      this.setNavMenu();
      this.loading = true;
      await this.loadNextSegment(true)
      this.loading = false;
    }
  },
  computed: {
    pillarSegments(){
      if (this.pillarsInfo && this.pillarId){
        const totalNumberOfPillars = _.size(this.pillarsInfo);
        const currentPillarIndex = _.findIndex(this.pillarsInfo, v => v.pillarId === this.pillarId);
        const totalNumberOfSegments = _.size(_.get(this.pillarsInfo, `[${currentPillarIndex}].segments`));
        const currentSegmentIndex = _.findIndex(_.get(this.pillarsInfo, `[${currentPillarIndex}].segments`), v => {
          return `${v.id}` === `${this.segmentId}`
        });

        return {
          pillarNumber: currentPillarIndex + 1,
          totalNumberOfPillars,
          segmentNumber: currentSegmentIndex + 1,
          totalNumberOfSegments
        }
      } else {
        return {}
      }
    },
  },
  watch: {
    async selectedNavItem(newVal){
      if (newVal >= 0 && newVal !== null){
        const clickedPillarInfo = _.get(this.pillarsInfo, `[${newVal}]`);
        const clickedPillarId = _.get(clickedPillarInfo, `pillarId`);
        if (`${clickedPillarId}` === `${this.pillarId}`) return;

        // const clickedPillarSegments = _.get(clickedPillarInfo, "segments");
        // const answeredOrSkippedSegments = _.filter(clickedPillarSegments, v => `${v.status}` === "1" || `${v.is_skipped}` === "1");
        // if (_.size(answeredOrSkippedSegments) !== _.size(clickedPillarSegments)) return;

        this.pillarId = clickedPillarId;
        this.segmentId = null;
        this.setNavMenu();
        this.loading = true;
        await this.loadNextSegment(true)
        this.loading = false;
      }
    },
    questionsSubmitting(){
      this.$nextTick().then(() => {
        if (this.$refs.pilquestionloading){
          this.$refs.pilquestionloading.scrollIntoView({ behavior: "smooth", inline: "center" })
        }
      });
    },
    segmentFinished(){
      this.$nextTick().then(() => {
        if (this.$refs.finishquestionbutton){
          this.$refs.finishquestionbutton.scrollIntoView({ behavior: "smooth", inline: "center" })
        }
      });
    }
  },
  async created() {
    await this.loadNextSegment();
  }
}
</script>

<template>
  <div>
    <Loader :loading="showLoader"/>
    <SurveyCompleted v-if="surveyCompleted" :pillar-info="pillarsInfo" :handleNavigatePillar="handleNavigatePillarFromCompletionScreen"/>
    <div class="pil-headline" v-if="!surveyCompleted && !this.loading" >
      <div class="pil-headline-left" v-if="!this.loading && this.pillarName && this.pillarSegments.segmentNumber">
        <div class="pil-headline-text">
          {{this.pillarName}} <span class="pil-headline-text-part">
<!--          ( {{this.pillarSegments.segmentNumber}} / {{this.pillarSegments.totalNumberOfSegments}} )-->
        </span>
        </div>
        <div v-if="!revisitingSegment" class="pil-skip-segment-button">
          <button @click="handleSkipSegment" class="flex items-center gap-2">
            <span class="material-symbols-outlined">skip_next</span>
            Skip
          </button>
        </div>
<!--        <div class="pil-read-only-text" v-if="revisitingSegment && readOnly">View only</div>-->
<!--        <div class="pil-read-only-text" v-if="revisitingSegment"><button @click="readOnly=!readOnly">-->
<!--          {{ readOnly ? 'Edit' : 'View only'}}-->
<!--        </button></div>-->
        <div :class="`${revisitingSegment && !surveyCompleted && readOnly ? 'pil-move-next' : 'pil-move-next-hidden'}`" @click="()=>loadNextSegment(true)">
          <button ref="finishquestionbutton" >
            Next
          </button>
        </div>

      </div>

      <div class="pil-headline-right" v-if="!this.loading && this.pillarSegments.pillarNumber">
<!--        ({{this.pillarSegments.pillarNumber}} / {{this.pillarSegments.totalNumberOfPillars}} Pillar)-->
      </div>
    </div>

    <div v-if="!this.loading && this.pillarName && this.pillarSegments.segmentNumber && !surveyCompleted" class="pil-pillar-info">
<!--      Dummy text {{this.pillarName}} -Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus in libero risus semper habitant arcu eget. Et integer facilisi eget diam. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus in libero risus semper habitant arcu eget. Et integer facilisi eget diam.-->
    </div>

<!--    <div v-if="!this.loading && readOnlySegmentSkipped">Segment Skipped</div>-->

    <div v-if="!this.loading && !surveyCompleted">
      <div>
        <div v-for="(question, questionIndex) in currentQuestionAnswer" :key="questionIndex">
          <div :class="questionIndex === 0 ? 'level1Question' : 'level2Question'">
            <Question
                :questionText="question?.title"
                :options="question?.options"
                :questionType="question?.type"
                :handleAnswer="(selection, optionNumber, otherOptions = {})=>handleAnswer(questionIndex, selection, optionNumber, otherOptions)"
                :isMainQuestion="false"
                :isAiGenerated="question?.isAiGenerated"
                :currentQuestion="(questionIndex + 1) === _.size(currentQuestionAnswer)"
                :answer="question?.answer"
                :revisitingSegment="revisitingSegment"
                :readOnly="readOnly"
            />
          </div>
        </div>
        <div v-if="questionsSubmitting" class="pil-question-loading" ref="pilquestionloading">
          <easy-spinner color="#7823dc" type="dots" size="23"/>
          {{questionsSubmitting && loadingAIQuestion ? 'The upcoming question is being generated by our LLM models, kindly wait..' : ''}}
          {{questionsSubmitting && !loadingAIQuestion ? 'Loading..' : ''}}
        </div>
      </div>

      <div :class="`${segmentFinished && !surveyCompleted && !revisitingSegment ? 'pil-finish-question' : 'pil-finish-question-hidden'}`" >
        <button ref="finishquestionbutton" class="pil-finish-question-button" @click="()=>loadNextSegment(true)">Submit Response</button>
      </div>
<!--      <div :class="`${revisitingSegment && !surveyCompleted ? 'pil-finish-question' : 'pil-finish-question-hidden'}`" >-->
<!--        <button ref="finishquestionbutton" class="pil-finish-question-button" @click="()=>loadNextSegment(true)">-->
<!--          {{ readOnly ? 'Next' : 'Submit Response'}}-->
<!--        </button>-->
<!--      </div>-->
    </div>
  </div>
</template>
<style scoped>
.pil-headline-text{
  font-size: 24px;
  font-weight: 700;
}

.pil-headline-text-part {
  font-size: 16px;
  font-weight: 300;
}
.temp-workaround{
  visibility: hidden;
}
.level1Question {
  padding-left: 10px;
}

.level2Question {
  padding-left: 10px;
}

.pil-headline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}

.pil-headline-right{
  color: #8737E1;
  font-weight: 700;
  font-size: 23px;
}

.pil-finish-question {
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.pil-finish-question-hidden {
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  visibility: hidden;
}

.pil-finish-question-button {
  width: 352px;
  height: 49px;
  background-color: #8737E1;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: #FFFFFF;
}

.pil-move-next {
  display: flex;
  margin-left: 20px;
  padding: 10px 40px;
  background: #DEC1FF;
  border-radius: 3px;
  cursor: pointer;
  color: #333333;
  font-weight: 600;
  border: 1px solid #8737E1;
  position: absolute;
  right: 40px;
}

.pil-move-next-hidden {
  visibility: hidden;
}

.pil-move-next-button {
  width: 80px;
  height: 49px;
  background-color: #8737E1;
  font-size: 18px;
  font-weight: 600;
  color: #FFFFFF;
}

.pil-question-loading {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-left: 20px;
}

.pil-headline-left {
  display: flex;
  text-align: center;
}

.pil-skip-segment-button {
  display: flex;
  margin-left: 20px;
  padding-left: 10px;
  padding-right: 10px;
  background: #FFFFFF;
  border-radius: 3px;
  cursor: pointer;
  color: #8737E1;
  font-weight: 400;
  border: 1px solid #8737E1;
}

.pil-read-only-text{
  display: flex;
  margin-left: 20px;
  background: #efefef;
  padding-left: 10px;
  padding-right: 10px;
  color: #8737E1;
  border-radius: 3px;
  cursor: pointer;
}

.pil-pillar-info {
  margin-top: -10px;
  margin-bottom: 40px;
  font-weight: 300;
}
</style>