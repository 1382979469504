import _ from 'lodash';
import pillarApi from '@/services/survey/pillarApi';

const formatResponseForUI = (resp) => {
    const respResult = resp?.data?.Result || {};
    return respResult?.nextQuestion_genai === 1 ? "GEN_AI" : "SEGMENT"
}

export default async ({
    pillarId,
    segmentId,
    questionAnswer
                      })=>{
    const response = await pillarApi.postData('pillar_segment', {
        "pillar_id": _.toNumber(pillarId),
        "segment": `${segmentId}`,
        "question_answer": questionAnswer
    }).catch(e => {
        return {
            error: true,
            errorMessage: e
        }
    });

    return formatResponseForUI(response);
}