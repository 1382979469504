<script>
import _ from "lodash";
import dfns from "date-fns";
import { useSnackbar } from "vue3-snackbar";
import invitedList from "@/services/superAdmin/invitedList";
import addUser from "@/services/superAdmin/addUser";
import Loader from "@/components/Loader.vue";
import TopStrip, {TOP_STRIP_MODES} from "@/components/TopStrip.vue";

const EMPTY_NEW_USER = {
  name: "",
  email: "",
  phone: "",
  title: ""
}
export default {
  name: "SuperadminDashboard",
  components: {Loader, TopStrip},
  data(){
    return {
      filter: {
        startDate: null,
        endDate: null,
      },
      invitedList: [],
      dataLoading: false,
      error: false,
      errorMessage: "",
      isAddingUser: false,
      addingUserDetails: _.clone(EMPTY_NEW_USER),
      TOP_STRIP_MODES,
      snackbar: useSnackbar(),
      _: _
    }
  },
  methods: {
    async getInvitedList(){
      this.dataLoading = true;
      const resp = await invitedList({startDate: this.filter.startDate, endDate: this.filter.endDate});
      if (!resp.error){
        this.invitedList = resp.data;
      } else {
        this.error = true;
        this.errorMessage = resp.error.message;
        this.snackbar.add({
          type: 'error',
          text: this.errorMessage
        })
      }
      this.dataLoading = false;
    },
    handleAddUser(){
      this.addingUserDetails = _.clone(EMPTY_NEW_USER);
      this.isAddingUser = true
    },
    async submitAddUser(){
      const [firstName, lastName] = _.split(this.addingUserDetails.name, " ").filter(v => v);
      this.dataLoading = true;
      const resp = await addUser({
        first_name: firstName,
        last_name: lastName,
        email: this.addingUserDetails.email,
        phone: this.addingUserDetails.phone,
        title: this.addingUserDetails.title
      });
      if (!resp.error){
        await this.getInvitedList();
        this.isAddingUser = false;
      } else {
        this.snackbar.add({
          type: 'error',
          text: 'An Error Occurred.'
        })
      }
      this.dataLoading = false;
    },
    handleBack(){
      this.isAddingUser = false;
    }
  },
  async created() {
    await this.getInvitedList();
  }
}
</script>
<template>
<div>
  <div class="top-strip">
    <TopStrip :mode="TOP_STRIP_MODES.PAGE"/>
  </div>
<!--  <div class="flex w-full justify-between" style="box-shadow: 0 1px 1px 0 #666666">-->
<!--    <div class="flex gap-3">-->
<!--      <div>Filter</div>-->
<!--      <div>Reset</div>-->
<!--    </div>-->
<!--    <div>-->
<!--      <span>Selected Period</span>-->
<!--      <span>12/12/2021 - 12/12/2022</span>-->
<!--    </div>-->
<!--  </div>-->
  <Loader :loading="dataLoading" :add-margin-for-left-nav="false" />
  <div class="flex flex-col gap-1 mt-3 pl-3" v-if="!isAddingUser">
    <div class="flex justify-between p-3">
      <div
          style="font-weight: 600; font-size: 31px;"
      >Dashboard</div>
      <div>
        <button
            style="border-radius: 6px; padding: 8px 16px; background: #8737E1; color: #FFFFFF; font-weight: 600; line-height: 24px; margin-right: 20px"
            @click="handleAddUser"
        >
          Add User/Partner
        </button>
      </div>
    </div>
    <div class="p-6 flex flex-col gap-3">
      <div
          v-for="(invite, inviteIndex) in invitedList"
          :key="invite.email"
          class="grid grid-cols-3 p-3"
          style="border: 1px solid #EBE8FF; border-radius: 15px"
      >
        <div class="flex">
          <div
              class="mr-3"
              style="border-radius: 50%; width: 48px; height: 48px; background: #8737E1; color: #FFFFFF; text-align: center; line-height: 48px"
          >{{inviteIndex+1}}</div>
          <div class="flex flex-col">
            <div style="font-size: 20px; font-weight: 700">{{invite.name}}</div>
            <div style="font-weight: 300">{{invite.title}}</div>
          </div>
        </div>
        <div class="flex flex-col">
          <div style="font-size: 20px; font-weight: 700">{{invite.email}}</div>
          <div style="font-weight: 300">{{""}}</div>
        </div>
        <div class="flex flex-col">
          <div style="font-size: 20px; font-weight: 700">Date</div>
          <div style="font-weight: 300">{{invite.invitedDate}}</div>
        </div>
      </div>
      <div v-if="_.isEmpty(invitedList) && !dataLoading">
        <div style="border: 1px solid #8737E1; line-height: 56px; height: 56px; background: #efefef; text-align: center">
          No records
        </div>
      </div>
    </div>
  </div>
  <div v-if="isAddingUser" style="width: 100%;">
    <div class="flex flex-col m-16 gap-6">

      <div class="flex flex-col gap-3">
        <div  style="font-size: 34px; font-weight: 600;">New User Details</div>
        <div class="flex flex-col gap-1">
          <div style="font-size: 16px; font-weight: 600">Name</div>
          <div><input type="text" style="border: 1px solid #D7D7D7" class="input-file-name" v-model="addingUserDetails.name"/></div>
        </div>
        <div class="flex flex-col gap-1">
          <div style="font-size: 16px; font-weight: 600" >Phone Number</div>
          <div><input type="text" style="border: 1px solid #D7D7D7" class="input-file-name" v-model="addingUserDetails.phone"/></div>
        </div>
        <div class="flex flex-col gap-1">
          <div style="font-size: 16px; font-weight: 600">Email</div>
          <div><input type="email" style="border: 1px solid #D7D7D7" class="input-file-name" v-model="addingUserDetails.email"/></div>
        </div>
        <div class="flex flex-col gap-1">
          <div style="font-size: 16px; font-weight: 600">Designation</div>
          <div><input type="text" style="border: 1px solid #D7D7D7" class="input-file-name" v-model="addingUserDetails.title"/></div>
        </div>

        <div class="flex justify-between" style="width: 560px">
          <button
              style="border-radius: 6px; padding: 8px 16px; background: #8737E1; color: #FFFFFF; font-weight: 600; line-height: 24px; margin-top: 20px"
              @click="handleBack"
          >
            Back
          </button>
          <button
              :disabled="_.isEmpty(addingUserDetails.name) || _.isEmpty(addingUserDetails.email)"
              style="border-radius: 6px; padding: 8px 16px; background: #8737E1; color: #FFFFFF; font-weight: 600; line-height: 24px; margin-top: 20px"
              @click="submitAddUser"
          >
            Add User
          </button>
        </div>
      </div>
    </div>

  </div>
</div>
</template>
<style scoped>
.input-file-name{
  border: 1px solid #8737E1;
  height: 36px;
  width: 560px;
  padding-left: 10px
}
button[disabled] {
  background: #9B9B9B !important;
  opacity: 0.5;
}
</style>