<script>
import NextArrowIcon from "@/assets/img/next-arrow.svg";

export default {
  name: "FreeText",
  props: {
    handleChange: {
      type: Function,
      required: true
    },
    valType: {
      type: String,
      required: true
    },
    currentQuestion: {
      type: Boolean,
      required: false,
      default: false
    },
    previousAnswer: {
      type: Object,
      required: false
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    revisitingSegment: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data(){
    return {
      value: this.previousAnswer ? this.previousAnswer.selection : "",
      showSubmitButton: false,
      NextArrowIcon
    }
  },
  methods: {
    handleEdit(){
      this.showSubmitButton = true;
    }
  },
  watch: {
    currentQuestion(newValue){
      this.showSubmitButton = !!newValue;
    }
  }
}
</script>

<template>
  <div class="que-free-text">
    <div class="que-free-text-input">
        <input
            :type="valType"
            v-model="value"
            class="que-free-text-area"
            :disabled="readOnly"
            placeholder="Your answer typed here.."
            v-on:keyup="handleEdit"
        />
      <button class="app-submit-button" v-if="showSubmitButton && !readOnly" @click="()=>{
        handleChange(this.value, 0)
      }"><img style="margin: 0 auto" :src="NextArrowIcon" alt=""></button>
    </div>
  </div>
</template>

<style scoped>
.que-free-text{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.que-free-text-input{
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.que-free-text-area {
  width: 595px;
  height: 51px;
  border: 1px solid #D7D7D7;
  border-radius: 3px;
  text-overflow: ellipsis;
  padding-left: 10px;
  outline: none;
  resize: none;
}

.que-free-text-input-submit-button {
  border: 1px solid #D7D7D7;
  border-radius: 3px;
  padding: 10px;
  background: #9B9B9B;
  height: 51px;
}
</style>