<script>
import _ from "lodash";
import { toast } from 'vue3-toastify';
import { ref, onMounted } from "vue";
import { pca } from "@/config/authConfig.js";
import axios from "axios";
import { useRouter } from 'vue-router';
import Loader from "@/components/Loader.vue";
import loginWithPassword from "@/services/user/loginWithPassword";

export default {
  components: {
    Loader
  },
  data() {
    return {
      userEmail: "",
      userPassword: ""
    }
  },
  setup() {
    const loading = ref(false);
    const profilePicture = ref(null);
    const router = useRouter(); // Get router instance
    const validateToken = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get("token");

      if (token) {
        try {
          loading.value = true;

          const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/api/admin/validate_user`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${token}`,
            },
          });
          const data = await response.json();

          if (data && data.StatusCode == 200) {
            localStorage.clear();
            localStorage.setItem("accessToken", data['Result']['token']);
            const userData = {
              email: data['Result']['email'],
              role: data['Result']['role'],
              firstName: data['Result']['first_name'],
              lastName: data['Result']['last_name'],
              company: data['Result']['company'],
              survey_id: data['Result']['survey_id']
            };

            localStorage.setItem("userData", JSON.stringify(userData));

            // Navigate based on role
            const role = userData.role;
            if (role === 1) {
              router.push({ name: "superadmindashboard" });
            } else if (role === 3) {
              router.push({ name: "partnerdashboard" });
            } else {
              router.push({ name: "landing" });
            }
          } 
          else if (data.StatusCode == 409) {
            toast(`${data['Result']['first_name']} ${data['Result']['last_name']} has been already registered. Please login using your credentials!`, {
              autoClose: 2000
            });
            loading.value = false;
            setTimeout(() => {
                router.push({ name: "login" });
            }, 3000);
          }
          else {
            toast("User validation failed. Please reach out to admin for access!", {
              autoClose: 2000
            });
            setTimeout(() => {
                router.push({ name: "login" });
            }, 3000);
          }
        } catch (error) {
          console.error("Error validating user:", error);
        } finally {
          loading.value = false;
        }
      }
    };

    onMounted(() => {
      validateToken();
    });

    const signIn = async () => {
      localStorage.clear();
      const loginRequest = {
        scopes: ["User.Read", "openid", "profile", "email"],
      };

      try {
        await pca.initialize();
        const response = await pca.loginPopup(loginRequest);
        localStorage.setItem('accessToken', String(response['accessToken']));
        console.log('response: ', response);
        console.log(response['accessToken'], "response['access_token']");
        // TODO: create an api to return the role of user from BE
        if (response['accessToken']) {
          let userData = {
            firstName: response.account.name.split(" ")[1].trim(),
            lastName: response.account.name.split(" ")[0].trim(),
            email: response['account']['username']
          };
          loading.value = true;
          fetch(`${process.env.VUE_APP_API_BASE_URL}/api/user/user-information`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              "Authorization": `Bearer ${localStorage.getItem('accessToken')}`
            }
          })
            .then((res) => {
              return res.json()
            })
            .then((data) => {
              if (data && data?.Result) {
                userData['role'] = data['Result']['user_role']
                localStorage.setItem("userData", JSON.stringify(userData));

                const roleVal = _.get(userData, "role");
                if (roleVal === 1) { // Super Admin
                  router.push({ name: 'superadmindashboard' });
                } else if (roleVal === 3) {
                  router.push({ name: 'partnerdashboard' });
                } else if (roleVal === 2) {
                  router.push({ name: 'landing' });
                }
              } else {
              }
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              loading.value = false;
            });

        }
      } catch (error) {
        alert("Authentication failed!");
        console.error("Authentication error:", error);
      }
    };

    const upSignIn = async (email, password) => {
      loading.value = true;
      if (email == '' || password == '') {
        toast("Please check email and Password!", {
        });
        loading.value = false;
      } else {
        const response = await loginWithPassword({
          email,
          password
        });
        if (!(response.data.token == null)) {
          const resp = response.data;
          const accessToken = resp.token;
          localStorage.clear();
          localStorage.setItem('accessToken', accessToken);

          let userData = {
            firstName: resp.firstName,
            lastName: resp.lastName,
            email: email
          };
          loading.value = true;
          fetch(`${process.env.VUE_APP_API_BASE_URL}/api/user/user-information`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              "Authorization": `Bearer ${localStorage.getItem('accessToken')}`
            }
          })
            .then((res) => {
              return res.json()
            })
            .then((data) => {
              if (data && data?.Result) {
                userData['role'] = data?.Result
                localStorage.setItem("userData", JSON.stringify(userData));
                router.push({ name: 'landing' });
                // router.push({ name: 'superadmindashboard' });
                // router.push({ name: 'partnerdashboard' });
              } else {
              }
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              loading.value = false;
            });
        }
        else{
          toast("Please check email and Password!", {
        });
        loading.value = false;
        }

      }

    };

    return { signIn, upSignIn, loading, profilePicture };
  },
};
</script>

<template>
  <div class="container">
    <!-- Image Section -->
    <div class="image-container">
      <img :src="require('@/assets/img/landing.png')" alt="Image" class="image" />
      <div class="overlay">
        <h1 class="headline">ExCap Survey</h1>
        <p class="sub-headline">Your Voice, Our Vision-Shape the Future with Every Survey!</p>
      </div>
    </div>

    <!-- Login Section -->
    <div class="login-container">
      <Loader :loading="loading" :add-margin-for-left-nav="false" />
      <img :src="require('@/assets/img/kearney-logo-black.svg')" class="logo" alt="logo" />
      <div class="login-content">
        <h2 class="title">Sign In</h2>
        <p class="subtitle">Welcome back! Please sign in to continue.</p>
        <div class="form">
          <input type="text" class="input" placeholder="E-mail" v-model="userEmail" />
          <input type="password" class="input" placeholder="Password" v-model="userPassword" />
          <button class="sign-in-button" @click="() => upSignIn(userEmail, userPassword)">Sign In</button>
          <button class="sso-button" @click="signIn">Sign In with Kearney SSO</button>
        </div>
        <p class="privacy-text">
          By clicking on 'Sign In', you confirm that you have read the
          <span class="privacy-statement">Privacy Statement</span>.
        </p>
      </div>
    </div>
  </div>
</template>



<style scoped>
/* Container */
.container {
  display: flex;
  flex-direction: row;
  height: 100vh;
  overflow: hidden;
  background-color: #f5f5f5;
}

.image-container {
  flex: 1.2;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  text-align: center;
  padding: 10px;
}

.headline {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.sub-headline {
  font-size: 1.5rem;
  font-weight: 300;
}

.login-container {
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6vh 6vw;
  background-color: white;
}

.logo {
  max-width: 120px;
  margin-bottom: 30px;
}

.login-content {
  max-width: 500px;
  width: 100%;
  text-align: center;
}

.title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 15px;
}

.subtitle {
  font-size: 1.25rem;
  color: #666;
  margin-bottom: 25px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.input {
  padding: 12px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.sign-in-button,
.sso-button {
  padding: 12px;
  font-size: 1rem;
  background-color: #000;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

.sso-button {
  background-color: #444;
}

.privacy-text {
  font-size: 0.85rem;
  color: #666;
  margin-top: 20px;
}

.privacy-statement {
  color: #a0186a;
  cursor: pointer;
  text-decoration: underline;
}

/* Media Queries */

/* Small Screens */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }

  .image-container {
    flex: 1;
  }

  .login-container {
    flex: 1.5;
    padding: 4vh 4vw;
  }

  .headline {
    font-size: 4vw;
  }

  .sub-headline {
    font-size: 3vw;
  }

  .logo {
    max-width: 25%;
  }

  .title {
    font-size: 5vw;
  }

  .subtitle {
    font-size: 3vw;
  }

  .input {
    font-size: 0.9rem;
  }

  .sign-in-button,
  .sso-button {
    font-size: 0.9rem;
  }
}

/* Medium Screens */
@media (min-width: 769px) and (max-width: 1200px) {
  .image-container {
    flex: 1.5;
  }

  .login-container {
    flex: 1.5;
    padding: 5vh 5vw;
  }

  .headline {
    font-size: 2.2rem;
  }

  .sub-headline {
    font-size: 1.5rem;
  }

  .logo {
    max-width: 20%;
  }

  .title {
    font-size: 1.8rem;
  }

  .subtitle {
    font-size: 1.2rem;
  }

  .input {
    font-size: 1rem;
  }

  .sign-in-button,
  .sso-button {
    font-size: 1rem;
  }
}

/* Large Screens */
@media (min-width: 1201px) {
  .image-container {
    flex: 5;
  }

  .login-container {
    flex: 3;
    padding: 7vh 8vw;
  }

  .headline {
    font-size: 2.5rem;
  }

  .sub-headline {
    font-size: 1.6rem;
  }

  .logo {
    max-width: 100%;
  }

  .title {
    font-size: 2rem;
  }

  .subtitle {
    font-size: 1.25rem;
  }

  .input {
    font-size: 1rem;
  }

  .sign-in-button,
  .sso-button {
    font-size: 1.1rem;
  }

  .container {
    max-width: 100%;
  }
}
</style>
