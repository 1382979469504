<script>
import _ from "lodash";
import Multiselect from "vue-multiselect";
const SELF_OPTION_LABEL = "Other (please specify)";
export default {
  name: "DropdownQuestion",
  components: {Multiselect},
  props: {
    title: {
      type: String,
      required: false
    },
    options: {
      type: Array,
      required: true
    },
    handleChange: {
      type: Function,
      required: true
    },
    previousAnswer: {
      type: Object,
      required: false
    },
    isMainQuestion: {
      type: Boolean,
      required: false,
      default: false
    },
    isAiGenerated: {
      type: Boolean,
      required: false,
      default: false
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    revisitingSegment: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data(){
    const previousSelection = this.previousAnswer ? {
      value: this.previousAnswer.optionNumber,
      name: this.options[_.toNumber(this.previousAnswer.optionNumber)]
    } : {};
    const selfOptionSelected = this.previousAnswer && this.previousAnswer.optionNumber === _.indexOf(this.options, SELF_OPTION_LABEL)
    return {
      isSelfOptionSelected: selfOptionSelected,
      optionSelected: previousSelection,
      value: _.cloneDeep(previousSelection),
      selfOptionValue: selfOptionSelected ? this.previousAnswer.selection : "",
      list: _.map(this.options, (v, i) => {
        return {
          value: i,
          name: v
        }
      }),
      _: _
    }
  },

  methods: {
    handleSelection(){
      if (this.value){
        if (!_.isEqual(this.value, this.optionSelected)){
          if (this.value.name === SELF_OPTION_LABEL){
            this.isSelfOptionSelected = true;
            this.handleChange(this.value.name, _.indexOf(this.options, this.value.name), {onlyTouch: true});
          } else {
            this.isSelfOptionSelected = false;
            this.handleChange(this.value.name, _.indexOf(this.options, this.value.name));
          }
        }
        setTimeout(()=>{
          this.$refs.multiselect.deactivate();
          this.$refs.multiselect.$refs.search.blur();
        })
      } else {
        this.isSelfOptionSelected = false;
        this.handleChange("", -1, {onlyTouch: true});
      }
    },
    handleSelfOptionValue(){
      if (this.value){
        this.handleChange(this.selfOptionValue, this.value.value);
      } else {
        this.handleChange("", -1, {onlyTouch: true});
      }
    }
  }
}
</script>

<template>
  <div class="ddq-container">
    <div class="ddq-control">
      <multiselect ref="multiselect" :disabled="readOnly" v-model="value" :options="list" :multiple="false" :close-on-select="true" :clear-on-select="false"
                   :preserve-search="true" placeholder="" label="name" track-by="name" :preselect-first="false"
                   @close="handleSelection"
      >
        <template #selection="{ values, search, isOpen }">
        <span class="multiselect__single"
              v-if="values.length"
        >{{ _.join(_.map(values, v => v.name), ", ") }}</span>
        </template>
      </multiselect>
    </div>

<!--    <select @change="e => {-->
<!--      const indexSelected = e.target.value;-->
<!--      const indexSelectedNum = _.toNumber(indexSelected);-->
<!--       const optionIndexLabel = _.get(_.find(this.options, v => v.value === indexSelectedNum), 'label');-->
<!--      handleSelection(optionIndexLabel)-->
<!--    }"-->
<!--            class="p-1"-->
<!--            name="options-select"-->
<!--            v-model="optionSelected"-->
<!--    >-->
<!--      <option disabled selected value> &#45;&#45; select an option &#45;&#45; </option>-->
<!--      <option v-for="opt in options" :key="opt.value" :value="opt.value" :selected="opt.value === optionSelected">{{opt.label}}</option>-->
<!--    </select>-->

    <div v-if="this.isSelfOptionSelected">
      <div class="ddq-self-option">
        <label class="ddq-self-option-label">Please Specify: </label>
        <div class="ddq-free-text-input">
          <textarea
              type="text"
              class="ddq-free-text-area flex-1 flex-grow"
              placeholder="Your answer typed here.."
              v-model="selfOptionValue"
          />
          <button class="app-submit-button" @click="()=>{
        handleSelfOptionValue(this.selfOptionValue)
      }">Submit</button>
        </div>
      </div>

    </div>
  </div>
</template>

<style scoped>
.ddq-container {
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.ddq-control{
  width: 600px;
}
select {
  width: 595px;
  height: 51px;
  border: 1px solid #D7D7D7;
  border-radius: 3px;
  text-overflow: ellipsis;
}


.main-question {
  color: #8737E1;
  font-size: 16px;
  font-weight: 700;
}

.ddq-ai-generated-text {
  font-weight: 800;
  color: #7823DC;
}

.ddq-self-option {
  display: flex;
  gap: 10px;
  width: 600px;
  flex-direction: column;
}

.ddq-self-option-label{
  padding-left: 0.25rem;
  font-weight: 150;
}

.ddq-free-text-input{
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 600px;
}

.ddq-free-text-area {
  height: 51px;
  border: 1px solid #D7D7D7;
  border-radius: 10px;
  text-overflow: ellipsis;
  padding-left: 10px;
  outline: none;
  resize: none;
}
.ddq-free-text-input-submit-button {
  border: 1px solid #D7D7D7;
  border-radius: 10px;
  padding: 10px;
  background: #9B9B9B;
}



</style>