import axios from 'axios';
import router from "@/router";
const API_URL = `${process.env.VUE_APP_API_BASE_URL}/api/admin`;

function getAuthHeaders() {
    const token = localStorage.getItem('accessToken');
    return {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`
    };
}

export default {
    // Get Question
    getData(endPoint) {
        return axios.get(`${API_URL}/${endPoint}`, {
            headers: getAuthHeaders()
        }).then(res=>{
            if (res && `${res.status}` === `${401}`){
                localStorage.clear(); // Clear local storage
                router.push({ name: "/login" });
            }
            return res;
        }).catch(e=>{
            if (`${e?.response?.status}` === `${401}`){
                localStorage.clear(); // Clear local storage
                router.push({ name: "/login" });
            }
        });
    },
    // Post Question
    postData(endPoint, payload, additionalHeaders = {}) {
        return axios.post(`${API_URL}/${endPoint}`, payload, {
            headers: Object.assign({}, getAuthHeaders(), additionalHeaders)
        }).then(res=>{
            if (res && `${res.status}` === `${401}`){
                localStorage.clear(); // Clear local storage
                router.push({ name: "/login" });
            }
            return res;
        }).catch(e=>{
            if (`${e?.response?.status}` === `${401}`){
                localStorage.clear(); // Clear local storage
                router.push({ name: "/login" });
            }
        });
    },
};
