<script>
import _ from "lodash";
import { useSnackbar } from "vue3-snackbar";
import setNewPassword from "@/services/survey/setNewPassword";
import router from "@/router";
import Loader from "@/components/Loader.vue";

export default {
  name: "ResetPassword",
  components: {Loader},
  data(){
    return {
      token: "",
      password: "",
      confirmPassword: "",
      email: "",
      loading: false,
      snackbar: useSnackbar(),
    }
  },
  methods: {
    async handleSubmit(){
      if (this.password === this.confirmPassword){
        this.loading = true;
        const resp = await setNewPassword({
          password: this.password,
          token: this.token
        });
        if (!resp.error){
          localStorage.clear(); // Clear local storage
          await router.push({name: "login"});
        } else {
          this.snackbar.add({
            type: 'error',
            text: 'An Error Occurred.'
          })
        }
      }
      this.loading = false;
    }
  },
  created() {
    this.token = _.get(this.$route, "query.token")
  }
}
</script>
<template>
  <div class="flex items-center h-screen">
    <!-- Left half with an image -->
    <Loader :loading="loading" :add-margin-for-left-nav="false" />
    <div class="w-1/2 h-screen relative flex justify-center items-center">
      <img :src="require('@/assets/img/kearney-logo-black.svg')" class="p-3" alt="logo"
           style="position: absolute; top: 10px; left: 10px;" />
      <div class="flex flex-col items-center">
        <h2 class="font-bold text-3xl">Set Password</h2>
        <p class="text-md text-gray-500 my-5">
          Welcome! Please set a password for your account.
        </p>
        <div style="border: 1px solid #a0186a" class="w-[350px] p-3 rounded-lg">
          <div class="flex flex-col gap-3">
            <div style="border: 1px solid #a0186a" class="w-full">
              <input class="w-full h-full p-3" placeholder="New Password" type="password" v-model="password"/>
            </div>
            <div style="border: 1px solid #a0186a" class="w-full">
              <input class="w-full h-full p-3" placeholder="Confirm New Password" type="password" v-model="confirmPassword"/>
            </div>
            <div style="color: #FFFFFF" class="flex w-full text-center gap-3 justify-end">
              <button class="bg-black text-white px-3 py-3 rounded-lg mt-5" @click="handleSubmit">Submit</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-1/2 h-screen relative">
      <img :src="require('@/assets/img/landing.png')" alt="Image" class="w-full h-screen object-cover" />
      <div class="absolute inset-0 flex items-center justify-center">
        <div class="lvp-intro">
          <div class="lvp-intro-headline">ExCap Survey</div>
          <div class="lvp-intro-sub-headline">Your Voice, Our Vision—Shape the Future with Every Survey!</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.privacy-statement {
  color: #a0186a;
  text-decoration: underline;
}

.lvp-intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #FFFFFF;
  width: 464px;
  text-align: center;
}

.lvp-intro-headline {
  font-size: 60px;
  font-weight: 800;
  line-height: 72px;
}

.lvp-intro-sub-headline {
  font-size: 32px;
  font-weight: 400;
  line-height: 51px;
}
</style>