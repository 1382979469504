<script>
import _ from "lodash";
import Multiselect from 'vue-multiselect';

import 'vue-multiselect/dist/vue-multiselect.css';

export default {
  components: {
    Multiselect
  },
  props: {
    title: {
      type: String,
      required: false
    },
    options: {
      type: Array,
      required: true
    },
    handleChange: {
      type: Function,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false
    },
    isMainQuestion: {
      type: Boolean,
      required: false,
      default: false
    },
    isAiGenerated: {
      type: Boolean,
      required: false,
      default: false
    },
    previousAnswer: {
      type: Object,
      required: false
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    revisitingSegment: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    const previousSelection = this.previousAnswer ? _.map(this.previousAnswer.optionNumber, (v, i) => {
      return {
        value: v,
        name: this.previousAnswer.selection[i]
      }
    }) : []
    return {
      optionSelected: previousSelection,
      value: _.cloneDeep(previousSelection),
      list: _.map(this.options, (v, i) => {
        return {
          value: i,
          name: v
        }
      }),
      _: _,
    }
  },
  methods: {
    onClose(){
      if (!_.isEmpty(this.value)){
        const numVal = _.sortBy(_.map(this.value, v => _.toNumber(v.value)))
        const label = _.map(numVal, v => this.options[v]);
        this.handleChange(label, numVal);
        setTimeout(()=>{
          this.$refs.multiselect.deactivate();
          this.$refs.multiselect.$refs.search.blur();
        })
      } else {
        this.handleChange([], [], {onlyTouch: true});
      }
    }
  },
}
</script>

<template>
  <div class="mss-container">
    <div class="mss-control">
      <multiselect :disabled="readOnly" ref="multiselect" v-model="value" :options="list" :multiple="true" :close-on-select="false" :clear-on-select="false"
                   :preserve-search="true" placeholder="" label="name" track-by="name" :preselect-first="false"
                   @close="onClose"
      >
        <template #selection="{ values, search, isOpen }">
        <span class="multiselect__single"
              v-if="values.length"
        >{{ _.join(_.map(values, v => v.name), ", ") }}</span>
        </template>
      </multiselect>
    </div>

  </div>
</template>

<style>
.mss-container{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.mss-control{
  width: 600px;
}

.multiselect__option--highlight {
  background-color: #f3f3f3;
  color: #000000;
}

.multiselect__option--highlight::after{
  background-color: #f3f3f3;
  color: #000000;
}
.multiselect__option--selected {
  background: #8737E11A;
  color: #000000;
}
.multiselect__option--selected.multiselect__option--highlight {
  background: #8737E11A;
  color: #000000;
}

.multiselect__option--selected.multiselect__option--highlight::after {
  display: inherit;
  background: #8737E11A;
  color: #000000;
}

.multiselect__option--selected::after {
  display: none;
}

</style>