<script>
import _ from "lodash";
import NextArrowIcon from "@/assets/img/next-arrow.svg";

const SELF_OPTION_LABEL = "Other (please specify)";

export default {
  props: {
    title: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    isMainQuestion: {
      type: Boolean,
      required: false
    },
    isMultiSelect: {
      type: Boolean,
      required: false,
      default: false
    },
    previousAnswer: {
      type: Object,
      required: false
    },
    handleChange: {
      type: Function,
      required: true
    },
    isAiGenerated: {
      type: Boolean,
      required: false,
      default: false
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    revisitingSegment: {
      type: Boolean,
      required: false,
      default: false
    },
    currentQuestion: {
      type: Boolean,
      required: false,
      default: false
    },
    maxSelection: {
      type: Number,
      required: false
    }
  },
  data(){
    return {
      checkboxesSelected: this.previousAnswer && !_.isEmpty(this.previousAnswer) ? _.reduce(this.previousAnswer.selection, (acc, v)=>{
        acc[v] = true;
        return acc;
      }, {}) : {},
      readOnly: this.previousAnswer && !_.isEmpty(this.previousAnswer),
      NextArrowIcon,
      SELF_OPTION_LABEL,
      selfOptionValue: "",
      uniq: new Date().getTime(),
      _: _
    }
  },
  computed: {
    showSubmitButton(){
      const allKeysExceptSelfOptionInCurrentSelection = _.filter(_.keys(this.checkboxesSelected),  k => k !== SELF_OPTION_LABEL)
      const anyCheckBoxOtherThanSelfOptionSelected = _.some(_.map(allKeysExceptSelfOptionInCurrentSelection, k => this.checkboxesSelected[k]), v => v);
      const selfOptionCheckBoxSelected = this.checkboxesSelected[SELF_OPTION_LABEL];

      if (!selfOptionCheckBoxSelected){
        if (this.maxSelection > 0){
          return _.size(allKeysExceptSelfOptionInCurrentSelection) === this.maxSelection
        }
        return anyCheckBoxOtherThanSelfOptionSelected;
      } else {
        if (this.maxSelection > 0){
          const selfOptionHasValue = _.trim(this.selfOptionValue) !== "";
          if (selfOptionHasValue){
            return _.size(allKeysExceptSelfOptionInCurrentSelection)+1 === this.maxSelection
          }
          return false;
        }
        return _.trim(this.selfOptionValue) !== ""
      }
    }
  },
  methods: {
    handleSubmit(){
      const chc = [];
      _.forOwn(this.checkboxesSelected, (v, k)=>{
        if (v){
          chc.push(k)
        }
      })
      if (this.isMultiSelect){
        const numVal = _.sortBy(_.reduce(this.options, (acc, v, i)=>{
          if (_.includes(chc, v)){
            acc.push(i);
          }
          return acc;
        }, []))
        const label = _.map(numVal, v => {
          let optionLabel = this.options[v];
          if (this.options[v] === SELF_OPTION_LABEL){
            optionLabel = this.selfOptionValue;
          }
          return optionLabel;
        });
        this.handleChange(label, numVal);
      } else {
        let optionLabel = chc[0];
        if (chc[0] === SELF_OPTION_LABEL){
          optionLabel = this.selfOptionValue;
        }
        this.handleChange([optionLabel], [_.indexOf(this.options, chc[0])])
      }
    },
    handleSelection(evt, option){
      if (this.readOnly){
        return;
      }
      const isNewSelect = evt.target.checked;
      if (this.isMultiSelect){
        if (isNewSelect){
          this.checkboxesSelected = {
            ...this.checkboxesSelected,
            [option]: true
          };
          if (this.maxSelection > 0){
            const keysForRemoval = _.filter(_.keys(this.checkboxesSelected), v => this.checkboxesSelected[v] && v!==option)
            this.checkboxesSelected = _.reduce([..._.takeRight(keysForRemoval, this.maxSelection-1), option], (acc, v)=>{
              acc[v] = true;
              return acc;
            }, {});
          }
        } else {
          this.checkboxesSelected = {
            ...this.checkboxesSelected,
            [option]: false
          };
        }
      } else {
        if (isNewSelect){
          this.checkboxesSelected = {[option]: true}
        } else {
          this.checkboxesSelected = {}
        }
      }

      if (option !== SELF_OPTION_LABEL && this.checkboxesSelected[SELF_OPTION_LABEL] && _.trim(this.selfOptionValue) === ""){
        this.checkboxesSelected[SELF_OPTION_LABEL] = false;
      }


      // if (!this.isMultiSelect){
      //   this.handleSubmit();
      // }

    }
  }
}
</script>

<template>
  <div class="cbg-container">
    <div class="cbg-option-group">
      <div class="cbg-option" v-for="option in options" :key="`${option}`">
        <div class="cbg-control-container">
          <input
              :id="`${option}${uniq}`"
              type="checkbox"
              :disabled="readOnly"
              :class="`${this.readOnly ? 'regular-radio-disabled' : ''} regular-radio`"
              @click="(e)=>handleSelection(e, option)"
              v-model="checkboxesSelected[option]"
          />
        </div>
        <div
            class="cbg-control-container"
            v-if="option === SELF_OPTION_LABEL && checkboxesSelected[option]"
        >
          <input
              type="text"
              class="cbg-self-option-tb"
              v-model="selfOptionValue"
              placeholder="Please specify"
          />
        </div>
        <div v-else :class="`cbg-label-container ${checkboxesSelected[option] ? 'cbg-label-container-selected' : ''}`">
          <label class="cbg-label-text" :for="`${option}${uniq}`" >{{option}}</label>
        </div>
      </div>
    </div>
    <div class="cbg-next-button">
      <button :class="`app-submit-button ${showSubmitButton && !readOnly ? 'app-submit-button-show': 'app-submit-button-hidden'}`"
              @click="()=>{
        handleSubmit()
      }"><img style="margin: 0 auto" :src="NextArrowIcon" alt=""></button>
    </div>

  </div>

</template>

<style scoped>
.cbg-control-container{
  height: 11px;
  align-self: flex-start;
  margin-top: 2px;
}
.cbg-self-option-tb-container {
  display: flex;
}
.cbg-self-option-tb {
  border: 1px solid #8737E1;
  width: 475px;
  height: 68px;
  padding: 10px;
}
.cbg-self-option-handle{
  width: 20px;
  height: auto;
  background: #8737E1;
}

.cbg-label-container{
  align-self: flex-start;
  cursor: pointer;
  width: 475px;
  height: 98px;
  border-radius: 6px;
  text-align: center;
  border: 1px solid #8737E1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cbg-label-text {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.cbg-label-container-selected {
  border: 1px solid #8737E1;
  background: #DEC1FF;
}

.cbg-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 980px;
}

.cbg-option-group {
  display: flex;
  width: 980px;
  justify-content: flex-start;
  gap: 20px;
  flex-wrap: wrap;
}

.cbg-option {
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 16px;
  font-weight: 300;
}



.regular-radio {
  -webkit-appearance: none;
  background-color: #FFFFFF;
  border: 1px solid #cacece;
  padding: 7px;
  position: relative;
  cursor: pointer;
  display: none;
}

.regular-radio:checked {
  background-color: #8737E1;
  border: 1px solid #ABABAB;
}

.regular-radio-disabled:checked {
  background-color: #ABABAB;
  border: 1px solid #ABABAB;
}

.cbg-ai-generated-text {
  font-weight: 800;
  color: #7823DC;
}

.cbg-next-button {
  align-self: flex-start;
  margin-top: 60px;
  margin-right: 10px;
}

.app-submit-button-show {
  height: 51px;
}

.app-submit-button-hidden {
  visibility: hidden;
}

</style>