<script>
import _ from "lodash";
import NextArrowIcon from "@/assets/img/next-arrow.svg";

export default {
  name: "Rate",
  props: {
    title: {
      type: String,
      required: false
    },
    options: {
      type: Array,
      required: true
    },
    handleChange: {
      type: Function,
      required: true
    },
    optionSelected: {
      type: String,
      required: false,
      default: []
    },
    disabled: {
      type: Boolean,
      required: false
    },
    currentQuestion: {
      type: Boolean,
      required: false,
      default: false
    },
    isMainQuestion: {
      type: Boolean,
      required: false,
      default: false
    },
    isAiGenerated: {
      type: Boolean,
      required: false,
      default: false
    },
    previousAnswer: {
      type: Object,
      required: false
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    revisitingSegment: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data(){
    return {
      list: _.map(this.options, (v, i) => {
        let prevFt = null;
        if (this.previousAnswer) {
          const indexOfOptionInSelection = _.indexOf(this.previousAnswer.selection, v);
          if (indexOfOptionInSelection > -1){
            prevFt = this.previousAnswer.optionNumber[indexOfOptionInSelection]
          }
        }
        return {
          name: v,
          id: i,
          val: prevFt ? prevFt : ""
        }
      }),
      readOnly: this.previousAnswer && !_.isEmpty(this.previousAnswer.selection),
      showSubmitButton: false,
      NextArrowIcon,
      uniq: new Date().getTime() + _.random(1, 9999),
      _: _
    }
  },
  methods: {
    handleControlEdit(){
      this.showSubmitButton = _.every(this.list, v => {
        return v.val !== ""
      })
    },
    handleAnswer(){
      const labels = _.map(this.list, v => v.name);
      const numVal = _.map(this.list, v => v.val);
      this.handleChange(labels, numVal);
      this.showSubmitButton = false;
    },
  }
}
</script>

<template>
  <div class="w-[900px]">
    <div v-for="opt in list" class="grid grid-cols-10 w-[900px] mb-8" :key="opt.name">
      <div class="col-span-5">{{opt.name}}</div>
      <div style="margin: auto; line-height: 40px" class="flex flex-col text-center" v-for="(none, index) in Array(5)" :key="index">
        <input
            type="radio"
            class="col-span-1"
            :id="`${opt.name}${uniq}${index}`"
            :name="`n${uniq}${opt.name}`"
            :value="index+1"
            v-model="opt.val"
            @change="handleControlEdit"
            :disabled="readOnly"
        />
        <label style="line-height: 26px" :for="`${opt}${uniq}${index}`">{{index+1}}</label>
      </div>
      <div class="col-span-5"></div>
      <div class="col-span-5" style="border: 1px solid #868FCA; height: 1px; margin-top: -36px"></div>
    </div>
    <div class="ftpo-submit-button-container">
      <button class="app-submit-button" v-if="!readOnly && showSubmitButton" @click="()=>{
        handleAnswer()
      }"><img style="margin: 0 auto" :src="NextArrowIcon" alt=""></button>
    </div>
  </div>
</template>

<style scoped>
input[type="radio"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.1em solid #9B9B9B;
  border-radius: 50%;
  transform: translateY(-0.01em);
  display: grid;
  place-content: center;
}

input[type="radio"]::before {
  content: "";
  width: 0.95em;
  height: 0.95em;
  border-radius: 50%;
  transform: scale(0);
  transition: 333ms transform ease-in-out;
  box-shadow: inset 1em 1em #8737E1;
}

input[type="radio"]:checked::before {
  transform: scale(1);
}

.ftpo-submit-button-container{
  align-self: flex-start;
  margin-top: -10px;
}
</style>