<script>
import _ from "lodash";
import NextArrowIcon from "@/assets/img/next-arrow.svg";

export default {
  name: "MultiSelectWithDetails",
  props: {
    title: {
      type: String,
      required: false
    },
    options: {
      type: Array,
      required: true
    },
    handleChange: {
      type: Function,
      required: true
    },
    mode: {
      type: Object,
      required: true
    },
    optionSelected: {
      type: String,
      required: false,
      default: []
    },
    disabled: {
      type: Boolean,
      required: false
    },
    currentQuestion: {
      type: Boolean,
      required: false,
      default: false
    },
    isMainQuestion: {
      type: Boolean,
      required: false,
      default: false
    },
    isAiGenerated: {
      type: Boolean,
      required: false,
      default: false
    },
    previousAnswer: {
      type: Object,
      required: false
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    revisitingSegment: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data(){
    return {
      list: _.map(this.options, (v, i) => {
        let prevFt = null;
        if (this.previousAnswer) {
          const indexOfOptionInSelection = _.indexOf(this.previousAnswer.selection, v);
          if (indexOfOptionInSelection > -1){
            prevFt = this.previousAnswer.optionNumber[indexOfOptionInSelection]
          }
        }
        return {
          name: v,
          id: i,
          ft: prevFt ? prevFt : ""
        }
      }),
      currentSelection: this.previousAnswer && this.previousAnswer.selection ? this.previousAnswer.selection : [],
      readOnly: this.previousAnswer && !_.isEmpty(this.previousAnswer.selection),
      showSubmitButton: false,
      NextArrowIcon,
      uniq: new Date().getTime() + _.random(1, 9999),
      _: _
    }
  },
  methods: {
    handleAnswer(){
      const labels = this.currentSelection;
      const numVal = _.map(this.currentSelection, v => {
        const listItem = _.find(this.list, v1 => v1.name === v);
        return listItem.ft;
      });
      this.handleChange(labels, numVal);
      this.showSubmitButton = false;
    },
    handleTextAreaEdit(){
      this.showSubmitButton = _.every(this.currentSelection, v => {
        const listItem = _.find(this.list, v1 => v1.name === v);
        return listItem.ft !== ''
      })
    },
    handleSelection(evt){
      const target =  _.get(evt, "target");
      const value = _.get(target, "value");
      if (this.mode.maxSelection === 1){
        this.currentSelection = [value];
      } else {
        const checked = _.get(target, "checked");
        if (checked){
          this.currentSelection.push(value);
        } else {
          this.currentSelection = _.filter(this.currentSelection, v => v !== value);
        }
        if (this.mode.maxSelection !== 'n'){
          const maxSelection = this.mode.maxSelection;
          this.currentSelection = _.takeRight(this.currentSelection, maxSelection);
        }
      }
      this.handleTextAreaEdit()
    }
  },
  watch: {
    currentQuestion(newValue){
      if (!newValue){
        this.showSubmitButton = false;
      }
    }
  },
}
</script>

<template>
  <div class="ftpo-container">
    <div>
      <div v-for="(opt, index) in list" :key="opt.name" :class="`grid grid-cols-2 w-[900px] mb-1 h-[50px]`">
        <div class="col-span-1 flex gap-1" style="margin: auto 0; align-items: center">
          <input
              type="checkbox"
              :id="`${opt.name}${uniq}`"
              :name="`n${uniq}`"
              :value="opt.name"
              @change="(e)=>handleSelection(e)"
              :checked="_.includes(this.currentSelection, opt.name)"
              :disabled="readOnly"
          />
          <label>{{ opt.name }}:</label>
        </div>

        <input
            v-if="_.includes(currentSelection, opt.name)"
            :type="mode.freeType"
            :ref="`ftpo${index}`"
            class="ftp-free-text-area"
            :disabled="readOnly"
            v-model="opt.ft"
            v-on:keyup="()=>{
                handleTextAreaEdit()
              }"
        />
      </div>
    </div>
    <div class="ftpo-submit-button-container">
      <button class="app-submit-button" v-if="!readOnly && showSubmitButton" @click="()=>{
        handleAnswer()
      }"><img style="margin: 0 auto" :src="NextArrowIcon" alt=""></button>
    </div>
  </div>
</template>

<style scoped>
.ftpo-container{
  display: flex;
  flex-direction: column;
  width: 600px;
}
.ftpo-submit-button-container{
  align-self: flex-start;
  margin-top: -10px;
}
.ftpo-opt-container{
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-bottom: 20px;
  justify-content: space-between;
  width: 900px;
}
.ftp-free-text-input{
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.ftp-free-text-area {
  width: 100%;
  height: 50px;
  border: 1px solid #D7D7D7;
  border-radius: 3px;
  text-overflow: ellipsis;
  padding-left: 10px;
  outline: none;
  resize: none;
}

.ftp-free-text-input-submit-button {
  border: 1px solid #D7D7D7;
  border-radius: 3px;
  background: #9B9B9B;
  height: 51px;
  padding: 10px;
}
input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid currentColor;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}
input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #8737E1;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}
</style>