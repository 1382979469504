import _ from 'lodash';
import adminApi from '@/services/superAdmin/adminApi';

const devResponse = {
    data: {
        Result:  []
    }
}

const formatResponseForUI = (resp) => {
    if (resp && resp.error){
        return resp;
    }
    const respResult = resp?.data?.Result || {};
    return {
        data: respResult,
        error: null
    }
}

export default async ({
    file
                      })=>{
    const response = await adminApi.postData(`upload`, {file}, {
        'Content-Type': 'multipart/form-data'
    }).catch(e => {
        return {
            error: true,
            errorMessage: e
        }
    });
    // const response = await new Promise((resolve)=>{
    //     setTimeout(()=>{
    //         resolve(devResponse)
    //     }, 300)
    // })
    return formatResponseForUI(response);
    // return {
    //     success: true
    // }
}