import _ from 'lodash';
import pillarApi from '@/services/survey/pillarApi';

const formatResponseForUI = (resp) => {
    const respResult = resp?.data?.Result || {};
    return {
        pillarName: respResult.pillar,
        pillarId: respResult.pillar_id,
        segmentId: _.get(respResult.segments, "[0].id"),
        questions: _.get(respResult.segments, "[0].questions"),
        isSkipped: respResult.is_skipped,
        status: respResult.status

    }
}

export default async (pillarId, segmentId)=>{
    let url = "pillar_segment";
    if (pillarId && segmentId){
        url = `${url}?pillar_id=${pillarId}&segment=${segmentId}`
    }
    const response = await pillarApi.getData(url).catch(e => {
        return {
            error: true,
            errorMessage: e
        }
    });
    // const response = await new Promise((resolve)=>{
    //     setTimeout(()=>{
    //         resolve(devResponse)
    //     }, 300)
    // })
    return formatResponseForUI(response);
}