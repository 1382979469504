import _ from "lodash";
import pillarAPI from '@/services/pillarApi';

const formatResponseForUI = (resp) => {
    const respResult = resp?.data?.Result || {};
    return {};
};

export default async () => {
    try {
        // Send GET request to the server
        const response = await pillarAPI.getData('session/summary');

        if (response['status'] == 200) {
            return { success: true, message: response['data']['Result'] };
        }
        else {
            console.error("Error downloading the file:", error);
            return { success: false, message: "Failed to download the file" };
        }
    } catch (error) {
        console.error("Error downloading the file:", error);
        return { success: false, message: "Failed to download the file" };
    }
};
