import _ from 'lodash';
import pillarApi from '@/services/survey/pillarApi';

const formatResponseForUI = (resp)=>{
    const respResult = resp?.data?.Result || {};
    return !!respResult.success;
}

export default async ({
                          pillarId,
                          segmentId
                      })=>{
    const response = await pillarApi.getData(`skipillarsegment?pillar_id=${pillarId}&segment=${segmentId}`).catch(e => {
        return {
            error: true,
            errorMessage: e
        }
    });
    // const response = await new Promise((resolve)=>{
    //     setTimeout(()=>{
    //         resolve(devResponse)
    //     }, 300)
    // })
    return formatResponseForUI(response);
}