<script>
import _ from "lodash";
import NextArrowIcon from "@/assets/img/next-arrow.svg";

export default {
  name: "FreeTextPerOption",
  props: {
    title: {
      type: String,
      required: false
    },
    options: {
      type: Array,
      required: true
    },
    handleChange: {
      type: Function,
      required: true
    },
    optionSelected: {
      type: String,
      required: false,
      default: []
    },
    disabled: {
      type: Boolean,
      required: false
    },
    currentQuestion: {
      type: Boolean,
      required: false,
      default: false
    },
    isMainQuestion: {
      type: Boolean,
      required: false,
      default: false
    },
    isAiGenerated: {
      type: Boolean,
      required: false,
      default: false
    },
    previousAnswer: {
      type: Object,
      required: false
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    revisitingSegment: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data(){
    return {
      list: _.map(this.options, (v, i) => {
        return {
          name: v,
          id: i,
          ft: this.previousAnswer ? this.previousAnswer.selection[i] : ""
        }
      }),
      showSubmitButton: false,
      NextArrowIcon,
      _: _
    }
  },
  methods: {
    handleAnswer(){
      const labels = _.map(this.list, v => v.ft);
      const numVal = _.map(this.list, (v, i) => i);
      this.handleChange(labels, numVal);
      this.showSubmitButton = false;
    },
    handleTextAreaEdit(){
      if (this.allOptionSelected){
        this.showSubmitButton = true;
      }
    }
  },
  computed: {
    allOptionSelected(){
      return _.every(_.map(this.list, v => {
        return !_.isEmpty(v.ft)
      }), v => v)
    }
  },
  watch: {
    allOptionSelected(newValue){
      if (newValue){
        this.showSubmitButton = true;
      }
    },
    currentQuestion(newValue){
      if (!newValue){
        this.showSubmitButton = false;
      }
    }
  }
}
</script>

<template>
  <div class="ftpo-container">
    <div>
      <div v-for="(opt, index) in list" :key="opt.name" class="ftpo-opt-container">
        <label>{{ opt.name }}:</label>
        <div class="ftp-free-text-input">
          <textarea
              type="text"
              :ref="`ftpo${index}`"
              class="ftp-free-text-area"
              :disabled="readOnly"
              placeholder="Your answer typed here.."
              v-model="opt.ft"
              v-on:keyup="()=>{
                handleTextAreaEdit()
              }"
          />
        </div>
      </div>
    </div>
    <div class="ftpo-submit-button-container">
      <button class="app-submit-button" v-if="!readOnly && showSubmitButton" @click="()=>{
        handleAnswer()
      }"><img style="margin: 0 auto" :src="NextArrowIcon" alt=""></button>
    </div>
  </div>
</template>

<style scoped>
.ftpo-container{
  display: flex;
  flex-direction: column;
  width: 600px;
}
.ftpo-submit-button-container{
  align-self: flex-start;
  margin-top: -10px;
}
.ftpo-opt-container{
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 20px;
}
.ftp-free-text-input{
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.ftp-free-text-area {
  width: 595px;
  height: 51px;
  border: 1px solid #D7D7D7;
  border-radius: 3px;
  text-overflow: ellipsis;
  padding-left: 10px;
  outline: none;
  resize: none;
}

.ftp-free-text-input-submit-button {
  border: 1px solid #D7D7D7;
  border-radius: 3px;
  background: #9B9B9B;
  height: 51px;
  padding: 10px;
}
</style>