<script>
import _ from "lodash";
import UpIcon from "@/assets/img/up.svg";
import DownIcon from "@/assets/img/down.svg";
import NeutralIcon from "@/assets/img/neutral.svg";
import UpActiveIcon from "@/assets/img/up-active.svg";
import DownActiveIcon from "@/assets/img/down-active.svg";
import NeutralActiveIcon from "@/assets/img/neutral-active.svg";
export default {
  name: "UpDown",
  props: {
    title: {
      type: String,
      required: false
    },
    options: {
      type: Array,
      required: true
    },
    handleChange: {
      type: Function,
      required: true
    },
    optionSelected: {
      type: String,
      required: false,
      default: []
    },
    disabled: {
      type: Boolean,
      required: false
    },
    isMainQuestion: {
      type: Boolean,
      required: false,
      default: false
    },
    isAiGenerated: {
      type: Boolean,
      required: false,
      default: false
    },
    keyWords: {
      type: Array,
      required: false,
      default: ["Yes", "Neutral", "No"]
    },
    previousAnswer: {
      type: Object,
      required: false
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    revisitingSegment: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data(){
    return {
      UpIcon,
      DownIcon,
      NeutralIcon,
      UpActiveIcon,
      DownActiveIcon,
      NeutralActiveIcon,
      list: _.map(this.options, (v, i)  => {
        return {
          name: v,
          id: i,
          keyWordSelected: this.previousAnswer ? _.get(this.previousAnswer, `selection[${i}]`, null) : null
        }
      }),
      debouncedHandleChange: _.debounce(this.handleChange, 250, ),
      _: _
    }
  },
  methods: {
    handleAnswer(){
      const labels = _.map(this.list, v => v.keyWordSelected);
      const numVal = _.map(this.list, (v, i) => i);
      this.debouncedHandleChange(labels, numVal);
    },
    handleEdit(){
      if (this.allOptionSelected){
        this.handleAnswer()
      }
    }
  },
  computed: {
    allOptionSelected(){
      return _.every(_.map(this.list, v => {
        return v.keyWordSelected !== null
      }), v => v)
    }
  },

  watch: {
    allOptionSelected(newValue){
      if (newValue){
        this.handleAnswer()
      }
    }
  }
}
</script>

<template>
<div>
  <div class="udq-list">
    <div v-for="opt in list" :key="opt">
      <div class="udq-list-item">
        <div class="udq-list-item-option-name">{{opt.name}}:</div>
        <div class="udq-list-item-keywords">
          <div :class="`${readOnly ? 'udq-list-item-keyword-disabled' : 'udq-list-item-keyword'}
                        ${opt.keyWordSelected === _.get(keyWords, '[0]', '')
                          ? 'udq-list-item-keyword-active': ''}`" @click="()=>{
            if (readOnly) return;
            opt.keyWordSelected = _.get(keyWords, '[0]', '')
            handleEdit();
          }">
            <div :class="`udq-list-item-keyword-text ${opt.keyWordSelected === _.get(keyWords, '[0]', '')
                          ? 'udq-list-item-keyword-text-active': ''}`">
              {{_.get(keyWords, "[0]", "")}}
            </div>
          </div>
          <div :class="`${readOnly ? 'udq-list-item-keyword-disabled' : 'udq-list-item-keyword'}
                        ${opt.keyWordSelected === _.get(keyWords, '[1]', '')
                          ? 'udq-list-item-keyword-active': ''}`" @click="()=>{
            if (readOnly) return;
            opt.keyWordSelected = _.get(keyWords, '[1]', '');
            handleEdit();
          }">
            <div :class="`udq-list-item-keyword-text ${opt.keyWordSelected === _.get(keyWords, '[1]', '')
                          ? 'udq-list-item-keyword-text-active': ''}`">
              {{_.get(keyWords, "[1]", "")}}
            </div>
          </div>
          <div :class="`${readOnly ? 'udq-list-item-keyword-disabled' : 'udq-list-item-keyword'}
                        ${opt.keyWordSelected === _.get(keyWords, '[2]', '')
                          ? 'udq-list-item-keyword-active': ''}`" @click="()=>{
            if (readOnly) return;
            opt.keyWordSelected = _.get(keyWords, '[2]', '');
            handleEdit();
          }">
            <div
                :class="`udq-list-item-keyword-text ${opt.keyWordSelected === _.get(keyWords, '[2]', '')
                          ? 'udq-list-item-keyword-text-active': ''}`">
              {{_.get(keyWords, "[2]", "")}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="allOptionSelected">
    {{""}}
  </div>
</div>
</template>

<style scoped>
.udq-title{
  margin-bottom: 10px;
}

.udq-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.udq-list-item {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 10px;
}

.udq-list-item-keywords {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.udq-list-item-keyword {
  display: flex;
  align-items: center;
  gap: 3px;
  cursor: pointer;
  border: 1px solid #9B9B9B;
  padding: 5px;
  color: #666666;
  border-radius: 3px;
}

.udq-list-item-keyword:hover{
  background: #8737E11A;
}

.udq-list-item-keyword-active {
  background: #8737E11A;
  border: 1px solid #8737E11A;
  box-shadow: 0 1px 1px 0 #8737E11A;
}

.udq-list-item-keyword-disabled {
  display: flex;
  align-items: center;
  gap: 3px;
  cursor: pointer;
  border: 1px solid #9B9B9B;
  padding-right: 10px;
  padding-left: 5px;
  box-shadow: 0 1px 1px 0 #666666;
  background: #efefef;
}

.udq-list-item-keyword-image {
  height: 20px;
  width: 20px;
}

.udq-list-item-keyword-text-active {
  color: #8737E1;
}

.udq-free-text-input-submit-button {
  border: 1px solid #D7D7D7;
  border-radius: 10px;
  background: #9B9B9B;
  margin-top: 10px;
  height: 40px;
  line-height: 40px;
  width: 90px;
}

</style>