import _ from 'lodash';
import pillarApi from '@/services/survey/pillarApi';

const devResponse = {
    data: {
        Result: {
            "message": "Success",
            "results": [
                {
                    "pillar_id": 1,
                    "pillar_name": "General",
                    "segments": [
                        {
                            "id": "1",
                            "static_questions": 4,
                            "status": 1
                        }
                    ]
                },
                {
                    "pillar_id": 2,
                    "pillar_name": "Capital Strategy & Planning",
                    "segments": [
                        {
                            "id": "3",
                            "static_questions": 2,
                            "status": 1
                        },
                        {
                            "id": "4",
                            "static_questions": 3,
                            "status": 1
                        },
                    ]
                },
                {
                    "pillar_id": 3,
                    "pillar_name": "Design & Engineering",
                    "segments": [
                        {
                            "id": "2",
                            "static_questions": 4,
                            "status": 0,
                            "is_skipped": 0
                        },
                    ]
                }
            ],
            "success": true
        }
    }
}

const formatResponseForUI = (resp) => {
    const respResult = resp?.data?.Result?.results || {};
    return _.map(respResult, v => {
        return {
            pillarId: v.pillar_id,
            pillarName: v.pillar_name,
            segments: _.map(v.segments, v1 => {
                return {
                    ...v1,
                    id: _.toNumber(v1.id)
                }
            })
        }
    })
}

export default async ()=>{
    const response = await pillarApi.getData('status').catch(e => {
        return {
            error: true,
            errorMessage: e
        }
    });
    // const response = await new Promise((resolve)=>{
    //     setTimeout(()=>{
    //         resolve(devResponse)
    //     }, 300)
    // })
    return formatResponseForUI(response);
}