<script>
import _ from "lodash";
import AnyUserIcon from "@/assets/img/Group.svg";
import DownArrowIcon from "@/assets/img/down-arrow.svg";
import UpArrowIcon from "@/assets/img/up-arrow.svg";
import {jwtDecode} from "jwt-decode";

export const TOP_STRIP_MODES = {
  PAGE: "PAGE",
  SURVEY: "SURVEY"
};

export default {
  name: "TopStrip",
  props: {
    progressPercent: {
      type: Number,
      required: false,
      default: 0
    },
    mode: {
      type: String,
      required: false,
      default: TOP_STRIP_MODES.SURVEY
    }
  },
  data(){
    return {
      AnyUserIcon,
      DownArrowIcon,
      UpArrowIcon,
      showingMenu: false,
      TOP_STRIP_MODES
    }
  },
  methods: {
    handleUserMenuButtonClick(){
      this.showingMenu = !this.showingMenu
    },
    logout() {
      localStorage.clear();
      this.$router.push({ name: "login" });
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.showingMenu = false
      }
    }
  },
  computed: {
    userName(){
      const token = localStorage.getItem('accessToken')
      if (!token){
        localStorage.clear(); // Clear local storage
        this.$router.push({ name: "login" }); // Redirect to home page
      }
      const decoded = jwtDecode(token);
      if (!decoded || !decoded.name){
        const userData = JSON.parse(_.get(localStorage, "userData", '{}'));
        return `${_.get(userData, "firstName", "")} ${_.get(userData, "lastName", "")}`
      }
      return decoded.name;
    }
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },
}
</script>

<template>
  <div class="ts-container">

    <div v-if="mode === TOP_STRIP_MODES.PAGE">
      <div class="ts-brand-logo">
        KEARNEY
      </div>
    </div>
    <div class="survey-settings">
      <div class="header-outer" v-if="mode === TOP_STRIP_MODES.SURVEY">
        <div class="survey-settings-sec">
          <span class="material-symbols-outlined header-survey-settings-icon">
          add_circle
        </span>
          Survey settings
          <div>
        </div>
        </div>
        <span class="material-symbols-outlined header-survey-settings-icon">
          help
        </span>
      </div>
      <div class="settings-divider"></div>
      <div class="survey-notifications">
        <span class="material-symbols-outlined  header-survey-settings-icon">
          notifications
        </span>
      </div>
      <div class="ts-user-menu-container">
        <div class="ts-user-menu" @click="handleUserMenuButtonClick">
          <div class="ts-user-menu-user-image" :style="`background-image: url(${AnyUserIcon});`"></div>
          <div class="flex-grow flex-1">{{userName}}</div>
          <button>
            <img :src="showingMenu ? UpArrowIcon : DownArrowIcon" alt="" />
          </button>
        </div>
        <div v-if="showingMenu" class="ts-user-menu-option">
          <ol class="ts-user-menu-option-list">
            <li class="ts-user-menu-option-listitem" @click="logout">Logout</li>
          </ol>
        </div>
      </div>
    </div>

  </div>
</template>

<style scoped>
.ts-brand-logo {
  
  font-size: 32px;
  font-weight: 400;
  padding-left: 20px;
}
.ts-container{
  width: 100%;
  height: 68px;
  max-width: 100vw;
  display: flex;
  /* flex-direction: row; */
  box-shadow: 4px 4px 4px 0 #8737E11A;
  align-items: center;
  margin: 0;
  padding: 0 10px;
  right: 10px;
  /* overflow-x: hidden; */
}

.header-outer {
  display: none;
  gap: 10px;
}


.ts-progress-bar-inner {
  height: 28px;

}
.ts-progress-bar-text{
  padding-left: 10px;
  line-height: 28px;
  font-size: 14px;
  font-weight: 300;
  position: absolute;
}
.survey-settings {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
  margin-right: 10px;
}
.survey-settings-sec{
  display: flex;
  gap: 3px;
}
.survey-notifications {
  margin-top: 5px;
  display: none;
}
.ts-user-menu-container {
  height: 50px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.ts-user-menu {
  width: 100%;
  height: 100%;
  border-radius: 35px;
  display: flex;
  box-shadow: 0 0 7px 0 #00000030;
  align-items: center;
  padding: 10px;
}

.ts-user-menu-user-image {
  height: 35px;
  width: 35px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 10px;
}

.ts-user-menu-option {
  width: 80%;
  align-self: center;
  margin-top: 3px;
}

.ts-user-menu-option-list {
  background-color: #FFFFFF;
}

.ts-user-menu-option-listitem {
  height: 100%;
  box-shadow: 0 0 7px 0 #00000030;
  align-items: center;
  border-radius: 10px;
  text-align: center;
  padding: 10px;
  position: relative;
  z-index: 999;
  background: #FFFFFF;
}
.header-survey-settings-icon {
  color: #9B9B9B;
  cursor: pointer;
  font-variation-settings:
      'FILL' 0,
      'wght' 300,
      'GRAD' 0,
      'opsz' 24
}
.settings-divider{
  height: 48px;
  width: 1px;
  border: 1px solid #9B9B9B1A;
  display: none;
}

@media (min-width: 1024px) and (max-width: 1440px) {
  .ts-container {
    width: 100vw; 
  }
}

@media (max-width: 478px) {
  .ts-container {
    margin-left: 2rem;
    max-width: 100%;
    min-width: 220vw;
  }
}
@media (max-width: 375px) {
  .ts-container {
    margin-left: 2rem;
    max-width: 100%;
    min-width: 245vw;
  }
}@media (max-width: 320px) {
  .ts-container {
    margin-left: 2rem;
    max-width: 100%;
    min-width: 290vw;
  }
}
</style>
