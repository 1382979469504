import _ from 'lodash';
import pillarApi from '@/services/survey/pillarApi';

const devResponse = {
    data: {
        Result: {
            "message": "Success",
            "results": [
                {
                    "pillar_id": 1,
                    "pillar_name": "General",
                    "segments": [
                        {
                            "id": "1",
                            "response": [
                                {
                                    "answer": {
                                        "optionNumber": [
                                            1,
                                            3
                                        ],
                                        "selection": [
                                            "South America",
                                            "Western Europe"
                                        ]
                                    },
                                    "options": [
                                        "North America",
                                        "South America",
                                        "Eastern Europe",
                                        "Western Europe",
                                        "Middle East",
                                        "Africa",
                                        "Eurasia (CIS / Russia)",
                                        "East Asia (China, Japan, Taiwan, South Korea)",
                                        "Southeast Asia",
                                        "Oceania (Australia, New Zealand, Papua New Guinea, Pacific Islands)",
                                        "Other (please specify)"
                                    ],
                                    "parent": [],
                                    "parentOptions": [],
                                    "questionId": "0",
                                    "questionText": "1.1 In which geographies is your company primarily operating?",
                                    "staticQuestionIndex": 0,
                                    "type": "MS"
                                },
                                {
                                    "answer": {
                                        "optionNumber": [
                                            1,
                                            3
                                        ],
                                        "selection": [
                                            "South America",
                                            "Western Europe"
                                        ]
                                    },
                                    "options": [
                                        "North America",
                                        "South America",
                                        "Eastern Europe",
                                        "Western Europe",
                                        "Middle East",
                                        "Africa",
                                        "Eurasia (CIS / Russia)",
                                        "East Asia (China, Japan, Taiwan, South Korea)",
                                        "Southeast Asia",
                                        "Oceania (Australia, New Zealand, Papua New Guinea, Pacific Islands)",
                                        "Other (please specify)"
                                    ],
                                    "parent": [],
                                    "parentOptions": [],
                                    "questionId": "1",
                                    "questionText": "1.1.1 In which geographies will most of your future projects be completed?",
                                    "staticQuestionIndex": 1,
                                    "type": "MS"
                                },
                                {
                                    "answer": {
                                        "optionNumber": [
                                            0,
                                            1
                                        ],
                                        "selection": [
                                            "New Construction",
                                            "Facility Upgrade, Retooling or Repurposing"
                                        ]
                                    },
                                    "options": [
                                        "New Construction",
                                        "Facility Upgrade, Retooling or Repurposing",
                                        "Facility Maintenance, Repair or Minor Upgrade",
                                        "Other (please specify)"
                                    ],
                                    "parent": [],
                                    "parentOptions": [],
                                    "questionId": "2",
                                    "questionText": "1.1.1.1 Please select the types of capex projects your organization typically executes.",
                                    "staticQuestionIndex": 2,
                                    "type": "MS"
                                },
                                {
                                    "answer": {
                                        "optionNumber": [
                                            0,
                                            1,
                                            2,
                                            3
                                        ],
                                        "selection": [
                                            "yes",
                                            "no",
                                            "no",
                                            "no"
                                        ]
                                    },
                                    "options": [
                                        "New Construction",
                                        "Facility Upgrade, Retooling or Repurposing",
                                        "Facility Maintenance, Repair or Minor Upgrade",
                                        "Other (please specify)"
                                    ],
                                    "parent": [],
                                    "parentOptions": [],
                                    "questionId": "3",
                                    "questionText": "1.1.1.1.1 What is your average project size by project type? (Free quantitative answers for each answer choice)",
                                    "staticQuestionIndex": 3,
                                    "type": "FTPO"
                                },
                                {
                                    "answer": {
                                        "optionNumber": 0,
                                        "selection": "some"
                                    },
                                    "isAiGenerated": true,
                                    "options": [],
                                    "parent": [],
                                    "parentOptions": [],
                                    "questionId": 4,
                                    "questionText": "1.1.1.1.1.1 Can you provide the average budget for each type of capex project your organization typically executes?",
                                    "type": "FT"
                                },
                                {
                                    "answer": {
                                        "optionNumber": 0,
                                        "selection": "no"
                                    },
                                    "isAiGenerated": true,
                                    "options": [],
                                    "parent": [],
                                    "parentOptions": [],
                                    "questionId": 5,
                                    "questionText": "Could you please specify the average budget in numerical terms for each type of capex project your organization typically executes?",
                                    "type": "FT"
                                }
                            ]
                        }
                    ]
                }
            ],
            "success": true
        }
    }
}

const formatResponseForUI = (resp) => {
    const respResult = _.get(resp, "data.Result.results[0]")|| {};
    return {
        pillarId: respResult.pillar_id,
        pillarName: respResult.pillar_name,
        segmentId: _.get(respResult, "segments[0].id"),
        questions: _.get(respResult, "segments[0].response")
    }
}


export default async ({
    pillarId,
    segmentId
                      })=>{
    const response = await pillarApi.getData(`fetchpillarsegmentdetails?pillar_id=${pillarId}&segment=${segmentId}`).catch(e => {
        return {
            error: true,
            errorMessage: e
        }
    });
    // const response = await new Promise((resolve)=>{
    //     setTimeout(()=>{
    //         resolve(devResponse)
    //     }, 300)
    // })
    return formatResponseForUI(response);
}