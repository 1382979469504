import axios from 'axios';

const API_URL = `${process.env.VUE_APP_API_BASE_URL}/api`;

function getAuthHeaders() {
  const token = localStorage.getItem('accessToken');
  return {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Authorization: `Bearer ${token}`,
  };
}

export default {
  // Get Data with optional query parameters
  getData(endPoint, options = {}) {
    return axios.get(`${API_URL}/${endPoint}`, {
      headers: getAuthHeaders(),
      ...options, // Spread additional options like params
    });
  },

  // Post Data
  postData(endPoint, payload) {
    return axios.post(`${API_URL}/${endPoint}`, payload, {
      headers: getAuthHeaders(),
    });
  },
};
