<script>
import _ from "lodash";
import NextArrowIcon from "@/assets/img/next-arrow.svg";

export default {
  name: "RBPO",
  props: {
    title: {
      type: String,
      required: false
    },
    options: {
      type: Array,
      required: true
    },
    answerCols: {
      type: Array,
      required: true
    },
    handleChange: {
      type: Function,
      required: true
    },
    optionSelected: {
      type: String,
      required: false,
      default: []
    },
    disabled: {
      type: Boolean,
      required: false
    },
    currentQuestion: {
      type: Boolean,
      required: false,
      default: false
    },
    isMainQuestion: {
      type: Boolean,
      required: false,
      default: false
    },
    isAiGenerated: {
      type: Boolean,
      required: false,
      default: false
    },
    previousAnswer: {
      type: Object,
      required: false
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    revisitingSegment: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data(){
    return {
      answerCols1: [{
        label: "Leading"
      }, {
        label: "Lagging"
      }],
      list: _.map(this.options, (v, i) => {
        let prevFt = null;
        if (this.previousAnswer) {
          const indexOfOptionInSelection = _.indexOf(this.previousAnswer.selection, v);
          if (indexOfOptionInSelection > -1){
            prevFt = this.previousAnswer.optionNumber[indexOfOptionInSelection]
          }
        }
        return {
          name: v,
          id: i,
          val: prevFt ? prevFt : ""
        }
      }),
      readOnly: this.previousAnswer && !_.isEmpty(this.previousAnswer.selection),
      showSubmitButton: false,
      NextArrowIcon,
      uniq: new Date().getTime() + _.random(1, 9999),
      _: _
    }
  },
  methods: {
    handleAnswer(){
      const labels = _.map(this.list, v => v.name);
      const numVal = _.map(this.list, v => v.val);
      this.handleChange(labels, numVal);
      this.showSubmitButton = false;
    },
    handleControlEdit(evt){
      this.showSubmitButton = _.every(this.list, v => {
        return !_.isEmpty(v.val)
      })
    }
  },
  watch: {
    currentQuestion(newValue){
      if (!newValue){
        this.showSubmitButton = false;
      }
    }
  },
}
</script>

<template>
  <div class="ftpo-container">
    <div>
      <div class="grid w-[900px] mb-1 gap-6" :style="`grid-template-columns: repeat(${_.size(answerCols)+2}, minmax(0, 1fr));`">
        <div class="col-span-2"></div>
        <div v-for="col in answerCols" :key="col.label" class="text-center">{{col.label}}</div>
      </div>
      <div v-for="(opt, index) in list" :key="opt.name" class="grid  w-[900px] mb-3 gap-6" :style="`grid-template-columns: repeat(${_.size(answerCols)+2}, minmax(0, 1fr));`">
        <div class="col-span-2 text-left pl-3" style="background: #F5F5F5; line-height: 40px; border: 1px solid #787878">{{ opt.name }}:</div>
        <div style="margin: auto; line-height: 40px" v-for="(col, colIndex) in answerCols" :key="col.label">
          <input
            type="radio"
            class="col-span-1"
            :id="`${col.label}${uniq}`"
            :name="`n${uniq}${opt.name}`"
            v-model="opt.val"
            :value="col.label"
            @change="handleControlEdit"
            :disabled="readOnly"
          />
        </div>
      </div>
    </div>
    <div class="ftpo-submit-button-container">
      <button class="app-submit-button" v-if="!readOnly && showSubmitButton" @click="()=>{
        handleAnswer()
      }"><img style="margin: 0 auto" :src="NextArrowIcon" alt=""></button>
    </div>
  </div>
</template>

<style scoped>
.ftpo-container{
  display: flex;
  flex-direction: column;
  width: 600px;
}
.ftpo-submit-button-container{
  align-self: flex-start;
  margin-top: 10px;
}
.ftpo-opt-container{
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-bottom: 20px;
  justify-content: space-between;
  width: 900px;
}
.ftp-free-text-input{
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.ftp-free-text-area {
  width: 595px;
  height: 51px;
  border: 1px solid #D7D7D7;
  border-radius: 3px;
  text-overflow: ellipsis;
  padding-left: 10px;
  outline: none;
  resize: none;
}

.ftp-free-text-input-submit-button {
  border: 1px solid #D7D7D7;
  border-radius: 3px;
  background: #9B9B9B;
  height: 51px;
  padding: 10px;
}

input[type="radio"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.1em solid #9B9B9B;
  border-radius: 50%;
  transform: translateY(-0.01em);
  display: grid;
  place-content: center;
}

input[type="radio"]::before {
  content: "";
  width: 0.95em;
  height: 0.95em;
  border-radius: 50%;
  transform: scale(0);
  transition: 333ms transform ease-in-out;
  box-shadow: inset 1em 1em #8737E1;
}

input[type="radio"]:checked::before {
  transform: scale(1);
}

</style>