import _ from "lodash";
import userApi from "@/services/user/userApi";


const formatResponseForUI = (resp) => {
    if (resp && resp.error){
        return resp;
    }
    const respResult = resp?.data?.Result || {};
    return {
        data: {
            token: respResult.token,
            firstName: respResult.first_name,
            lastName: respResult.last_name,
            email: respResult.email
        },
        error: null
    }
}

export default async (
    {
        email,
        password
    })=>{
    const response = await userApi.postData(`login`, {
        email,
        password
    }).catch(e => {
        return {
            error: true,
            errorMessage: e
        }
    });
    // const response = await new Promise((resolve)=>{
    //     setTimeout(()=>{
    //         resolve(devResponse)
    //     }, 300)
    // })
    return formatResponseForUI(response);
}