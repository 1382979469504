import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; // Import the router
import easySpinner from 'vue-easy-spinner';
import { SnackbarService } from "vue3-snackbar";
import "vue3-snackbar/styles";
import "./assets/css/tailwind.css";
import "./assets/css/global.css";
import { Vue3Toastify } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css'; // Import Toastify styles

createApp(App)
    .use(router) // Use the router instance
    .use(easySpinner, {
        prefix: 'easy',
    })
    .use(SnackbarService)
    .use(Vue3Toastify, {
        "type": "info",
        "transition": "flip",
        "dangerouslyHTMLString": true
    })
    .mount('#app');


// createApp(App).use(store).use(router).mount("#app");
