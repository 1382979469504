<script>
import _ from "lodash";
import draggable from "vuedraggable";
import DragIndicatorIcon from "@/assets/img/drag-indicator.svg";
import NextArrowIcon from "@/assets/img/next-arrow.svg";
import {MANUAL_SPECIFY} from "@/constants";

let idGlobal = 8;
export default {
  name: "clone-on-control",
  display: "Clone on Control",
  instruction: "Press Ctrl to clone element from list 1",
  order: 4,
  props: {
    title: {
      type: String,
      required: false
    },
    options: {
      type: Array,
      required: true
    },
    handleChange: {
      type: Function,
      required: true
    },
    optionSelected: {
      type: String,
      required: false,
      default: []
    },
    disabled: {
      type: Boolean,
      required: false
    },
    currentQuestion: {
      type: Boolean,
      required: false,
      default: false
    },
    isMainQuestion: {
      type: Boolean,
      required: false,
      default: false
    },
    isAiGenerated: {
      type: Boolean,
      required: false,
      default: false
    },
    previousAnswer: {
      type: Object,
      required: false
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    revisitingSegment: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    draggable
  },
  data() {
    const opts = _.map(this.options, (v, i) => {
      return {
        name: v,
        id: i + 1
      }
    })
    return {
      readOnly: !_.isEmpty(this.previousAnswer?.selection),
      DragIndicatorIcon,
      listOptions: [
          ...opts
      ],
      list: this.previousAnswer && !_.isEmpty(this.previousAnswer.selection) ? _.filter(opts, v =>{
        return !_.includes(this.previousAnswer.selection, v.name)
      }) : [
          ...opts
      ],
      lists: this.previousAnswer && !_.isEmpty(this.previousAnswer.selection) ? _.map(_.map(opts, v => []), (v1, i) => {
        if (_.includes(this.previousAnswer.optionNumber, i+1)){
          const optionName = this.previousAnswer.selection[_.indexOf(this.previousAnswer.optionNumber, i+1)]
          return [{
            name: optionName,
            id: _.get(_.find(opts, v => v.name === optionName), "id")
          }]
        }
        return []
      }) : _.map(opts, v => []),
      controlOnStart: true,
      NextArrowIcon,
      MANUAL_SPECIFY: MANUAL_SPECIFY,
      _: _
    };
  },
  methods: {
    clone({name}) {
      return {name, id: idGlobal++};
    },
    pullFunction() {
      return "clone";
    },
    start({originalEvent}) {
      this.controlOnStart = originalEvent.ctrlKey;
    },
    changeListOne(...args){
      // console.log(args);
    },
    changeListTwo(evt, index){
      const changeInfo = evt
      if (changeInfo.added){
        const newItem = changeInfo.added.element;
        const oldItems = _.filter(this.lists[index], v => v.id !== newItem.id)
        this.list = _.sortBy([...this.list, ...oldItems], v => v.id)
        this.lists[index] = [newItem]
      }
    },
    handleAnswer(){
      const {orderedItems, index} = _.reduce(this.lists, (acc, v, i)=>{
        if (!_.isEmpty(v)){
          acc.orderedItems.push(v[0].name);
          acc.index.push(i + 1)
        }
        return acc;
      }, {orderedItems: [], index: []})

      this.handleChange(orderedItems, index)
    },
  },
  computed: {
    dragOptions() {
      return {
        animation: 150,
        group: "people",
        disabled: false,
        ghostClass: "ghost"
      };
    },
    isNextEnabled(){
      return _.some(this.lists, v => !_.isEmpty(v))
    }
  },
};
</script>

<template>
  <div class="ddrn-question-container">
    <div class="ddrn-options-block">
      <div>
        Please rank the following services in order of interest
      </div>
      <div class="ddrn-options" :style="`height: ${_.size(listOptions)*56}px`">
        <draggable
            class="dragArea list-group"
            :list="list"
            :group="{ name: 'people', pull: pullFunction }"
            @start="start"
            v-bind="dragOptions"
            @change="changeListOne"
            handle=".handle"

        >
          <template #item="{ element }">
            <div :class="`list-group-item ${readOnly ? 'list-group-item-disabled' : 'list-group-item-enabled'} ${element.name === MANUAL_SPECIFY || readOnly ? '' : 'handle'}`">
              <div class="ddrn-list-item-cell">
                <img class="ddrn-list-item-cell-img" :src="DragIndicatorIcon" alt="" />
                <div class="list-item-label">
                  <input type="text" style="background: #F3F3F3;" v-if="element.name === MANUAL_SPECIFY && !readOnly" :placeholder="element.name" @change="e=> element.name = e.target.value"/>
                  <span v-else>{{ element.name }}</span>
                </div>
              </div>
            </div>
          </template>

        </draggable>
      </div>
    </div>

    <div class="ddrn-options-block-receiver">
      <div>
        Drag your choices here <span style="color: #8737E1;">(Higher up are of high priority)</span>
      </div>
      <div class="ddrn-options-receiver-container" :style="`height: ${_.size(listOptions)*56}px`">
        <div v-for="(opt, optNum) in listOptions" class="ddrn-options-receiver">
          <div class="ddrn-option-selected-indexes">
            <div class="ddrn-option-selected-index">
              {{optNum + 1}}
            </div>
          </div>
          <draggable
              class="dragArea list-group-receiver"
              :list="lists[optNum]"
              group="people"
              @change="e => changeListTwo(e, optNum)"
              :style="`height: 40px; margin-bottom: 13px;`"
              handle=".handle1"
          >
            <template #item="{ element }">
              <li :class="`list-group-item ${readOnly ? 'list-group-item-disabled' : 'list-group-item-enabled'} ${readOnly ? '' : 'handle1'}`">
                <div class="ddrn-list-item-cell-receiver">
                  <img class="ddrn-list-item-cell-receiver-img" :src="DragIndicatorIcon" alt="" />
                  <div class="list-item-label" style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden">
                    {{ element.name }}
                  </div>
                </div>
              </li>
            </template>
          </draggable>
        </div>
      </div>
    </div>
  </div>
  <div class="ddr-submit-button" v-if="!readOnly && isNextEnabled">
    <button class="app-submit-button" @click="handleAnswer"><img style="margin: 0 auto" :src="NextArrowIcon" alt=""></button>
  </div>
</template>
<style scoped>
.ddrn-question-container {
  display: flex;
  width: 900px;
  justify-content: space-between;
  gap: 20px;
}
.ddrn-options-block{
  display: flex;
  flex-direction: column;
  width: 55%;
  gap: 15px;
}
.ddrn-options-block-receiver{
  display: flex;
  flex-direction: column;
  width: 45%;
  gap: 15px;
}
.ddrn-options {
  display: flex;
  border: 1px solid #8737E1;
  background: #F3F3F3;
  border-radius: 8px;
  padding: 10px;
  gap: 20px;
}

.ddrn-options-receiver{
  display: flex;
  background: #F3F3F3;
  padding-left: 10px;
  gap: 10px;
}

.ddrn-options-receiver-container {
  padding: 10px;
  border-radius: 8px;
  background: #F3F3F3;
  width: 500px;
  border: 1px solid #8737E1;
}
.ddrn-option-selected-indexes{
  display: flex;
  flex-direction: column;
}
.ddrn-option-selected-index {
  line-height: 30px;
  border: 1px solid #8737E1;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  text-align: center;
  margin-top: 5px;
}
.ddrn-list-item-cell{
  display: flex;
  width: 100%;
  height: auto;
}
.ddrn-list-item-cell-img {
  height: 30px;
  width: 30px;
  align-self: center;
}
.ddrn-list-item-cell-receiver {
  display: flex;
  width: 100%;
}
.ddrn-list-item-cell-receiver-img{
  height: 30px;
  width: 30px;
  align-self: center;
}
li {
  list-style-type: none;
}
.ddr-container {
  width: 600px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.ddr-submit-button{
  align-self: flex-start;
  margin-top: 10px;
}

.ddr-submit-button-control{
  border: 1px solid #D7D7D7;
  border-radius: 3px;
  padding-left: 10px;
  padding-right: 10px;
  background: #8737E11A;
  height: 40px;
  color: #333333;
}

.button {
  margin-top: 35px;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 1;
  background: #8737E11A;
  color: #8737E11A;
  box-shadow: 0 0 8px 2px #00000066;
}

.drr-question-title{
  display: flex;
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 16px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #000000;
  justify-content: space-between;
  align-items: center;
}
.list-group {
  min-height: 40px;
  display: flex;
  flex-direction: column;
  gap: 13px;
  width: 100%;
}

.list-group-receiver {
  min-height: 40px;
  display: flex;
  flex-direction: column;
  gap: 13px;
  width: 90%;
}

.list-group-item {
  display: flex;
  justify-content: space-between;
  height: auto;
  padding: 10px;
}
.list-group-item-help-icon-container {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-right: 10px;

}
.list-group-item-enabled {
  cursor: move;
  min-height: 40px;
  box-shadow: 0 0 4px 0 #00000080;
  border: 1px solid #8737E1;
  padding-left: 10px;
  border-radius: 10px;
  align-items: center;
}

.list-group-item-disabled {
  cursor: move;
  min-height: 40px;
  box-shadow: 0 0 8px 2px #00000066;
  border: 1px solid rgb(215 215 215 / 30%);
  padding-left: 10px;
  border-radius: 10px;
}
.list-item-label {
  padding-left: 15px;
}
.sortable-chosen {
  background: #FFFFFF;
  color: #000000;
  box-shadow: 0 0 8px 2px #00000066;
}
.drr-free-text-input-submit-button {
  border: 1px solid #D7D7D7;
  border-radius: 10px;
  background: #9B9B9B;
  margin-top: 10px;
  height: 40px;
  line-height: 40px;
  width: 90px;
}
</style>