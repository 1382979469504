import _ from "lodash";
import downloadAPI from '@/services/download';

const formatResponseForUI = (resp) => {
    const respResult = resp?.data?.Result || {};
    return {};
};

export default async (user_email) => {
    try {
        // Prepare query parameters
        const params = user_email ? { user_email } : {};

        // Send GET request to the server
        const response = await downloadAPI.getData('session/download', {
            params, // Correctly pass the query parameters
            responseType: 'blob', // Ensure file download response is handled correctly
        });

        if (response['status'] == 200) {
            const fileName = user_email ? `user_answers_${user_email}.xlsx` : 'user_answers_all_users.xlsx';
            // Create a Blob and URL for the file
            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const downloadUrl = window.URL.createObjectURL(blob);

            // Trigger the file download
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            // Revoke the Blob URL
            window.URL.revokeObjectURL(downloadUrl);

            return { success: true, message: "File downloaded successfully" };
        }
        else {
            console.error("Error downloading the file:", error);
            return { success: false, message: "Failed to download the file" };
        }
    } catch (error) {
        console.error("Error downloading the file:", error);
        return { success: false, message: "Failed to download the file" };
    }
};
