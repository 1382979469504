import { PublicClientApplication } from "@azure/msal-browser";

const msalConfig = {
  auth: {
    clientId: process.env.VUE_APP_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${process.env.VUE_APP_TENANT_ID}`,
    redirectUri: process.env.VUE_APP_REDIRECT_URI,
  },
  cache: {
    cacheLocation: "localStorage",
  },
};

const pca = new PublicClientApplication(msalConfig);
export { pca };
