import _ from 'lodash';
import superAdminApi from '@/services/superAdmin/adminApi';

const devResponse = {
    data: {
        Result:  [{
            name: "Mohan Kumar",
            title: "Director",
            email: "mohan.kumar@kearney.com",
            invitedDate: "2024-11-13T14:30:43Z"
        }, {
            name: "Rakesh Sharma",
            title: "Principal",
            email: "rakesh.sharma@kearney.com",
            invitedDate: "2024-11-13T14:30:43Z"
        }, {
            name: "Sanjay Malhotra",
            title: "Partner",
            email: "sanjay.malhotra1@kearney.com",
            invitedDate: "2024-11-13T14:30:43Z"
        }]
    }
}

const formatResponseForUI = (resp) => {
    if (resp && resp.error){
        return resp;
    }
    const respResult = resp?.data?.Result || {};
    return {
        data: respResult,
        error: null
    }
}

export default async (
    {
        first_name,
        last_name,
        email,
        phone,
        title
    })=>{
    const response = await superAdminApi.postData(`partners`, {
        first_name,
        last_name,
        email,
    }).catch(e => {
        return {
            error: true,
            errorMessage: e
        }
    });
    // const response = await new Promise((resolve)=>{
    //     setTimeout(()=>{
    //         resolve(devResponse)
    //     }, 300)
    // })
    return {
        success: true,
    };
}