<script>
import _ from "lodash";
import numberToWords from "number-to-words";
import GreenTickIcon from "@/assets/img/green-tick.svg";
import { CircleProgressBar } from 'circle-progress.vue';
import summary from "@/services/survey/summary";
const COLOR_PALLETE = ["#55A8B5", "#E5102A", "#96CC00", "#EA7B03", "#8737E1", "#F1E6FE", "#2c9f4d"]

export default {
  name: "SurveyCompleted",
  components: { CircleProgressBar },
  props: {
    pillarInfo: {
      type: Object,
      required: true
    },
    handleNavigatePillar: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      GreenTickIcon,
      numberToWords,
      _: _,
      summary: "", // Define summary in the data function
    };
  },
  methods: {
    async getSummary(email) {
      this.dataLoading = true;
      console.log(email);

      let summaryRes;
      try {
        summaryRes = await summary(); // Await the API call
      } catch (e) {
        console.error(e);
      } finally {
        this.dataLoading = false;
      }

      if (summaryRes && summaryRes.message) {
        this.summary = summaryRes.message; // Update the reactive property
      } else if (summaryRes && summaryRes.error) {
        console.log(summaryRes.error);
      }
    }
  },
  mounted() {
    this.getSummary();
  },
  computed: {
    formattedSummary() {
      return this.summary.replace(/-\s/g, '<br>- '); 
    },
    progressData() {
      return _.reduce(this.pillarInfo, (acc, v, i) => {
        const completedSegments = _.filter(v.segments, segment => segment.status === 1);

        // Calculate total and completed counts
        const total = _.reduce(v.segments, (acc1, segment) => acc1 + segment.static_questions + 2, 0);
        const completed = _.reduce(completedSegments, (acc1, segment) => acc1 + segment.static_questions + 2, 0);

        // Determine color based on completion percentage
        const completionPercentage = (completed / total) * 100;
        let color = '';

        if (completionPercentage > 75) {
          color = 'green';
        } else if (completionPercentage > 45) {
          color = 'orange';
        } else {
          color = 'red';
        }

        acc.push({
          name: v.pillarName,
          total,
          completed,
          color
        });

        return acc;
      }, []);
    },
    skippingData() {
      const toReturn = _.reduce(
        this.pillarInfo,
        (acc, v) => {
          const skipped = _.filter(v.segments, segment => segment.is_skipped === 1);
          if (_.size(skipped) > 0) {
            acc.push({
              name: v.pillarName,
              skipped: _.size(skipped),
              total: _.size(v.segments)
            });
          }
          return acc;
        },
        []
      );
      return toReturn;
    }
  }
};

</script>

<template>
  <div class="sco-container">
    <!-- Scrollable content wrapper -->
    <div class="sco-scrollable-content">
      <div class="sco-thanks-container">
        <div class="sco-thanks-sub-container">
          <div class="sco-icon-container">
            <img style="width: 54px; height: 54px" :src="GreenTickIcon" alt=""/>
          </div>
          <div class="sco-thank-message">Thanks for Sharing.</div>
          <div class="sco-thank-sub-message" v-html="formattedSummary"></div>
        </div>
      </div>

      <div class="sco-skipping-info-section">
        <div v-for="sd in skippingData" :key="sd.name">
          <div class="sco-skipping-info">
            <div class="sco-skipping-info-line1">
              Skipped <span class="sco-skipping-info-line1-part">{{_.capitalize(numberToWords.toWords(sd.skipped))}} part{{sd.skipped > 1 ? 's' : ''}}</span> of <span  class="sco-skipping-info-line1-pillar">{{sd.name}}</span>
            </div>
            <div class="sco-skipping-info-line2">
              Kindly fill the questions by visiting the
              <span
                  class="sco-skipping-info-line2-link"
                  @click="()=>{
                    handleNavigatePillar(sd.name)
                  }"
              >Link
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="grid grid-cols-6 mt-6 gap-3 gridfscontainer">
        <div class="sco-circular-progress-container" :style="`border: 3px solid ${pd.color}`" v-for="pd in progressData" :key="pd.name">
          <CircleProgressBar
              class="sco-circular-progress-widget"
              :value="pd.completed"
              :max="pd.total"
              :colorBack="pd.color"
              :color-filled="pd.color"
              color-unfilled="#efefef"
              strokeWidth="10px"
          >
            <div class="sco-circular-progress-info-container">
              <div class="sco-circular-progress-info-pillarname">
                {{pd.name}}
              </div>
              <div class="sco-circular-progress-info-pillarprogress-container">
                <span :style="`color: ${pd.color}`" class="sco-circular-progress-info-pillarprogress-completed">{{pd.completed}}</span>
                <span :style="`color: ${pd.color}`" class="sco-circular-progress-info-pillarprogress-total"> / {{pd.total}}</span>
              </div>
            </div>
          </CircleProgressBar>
        </div>
      </div>
    </div>
  </div>
</template>


<style scoped>
.sco-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust as needed */
  overflow: hidden; /* Prevent scrolling on the entire container */
}

.sco-scrollable-content {
  width: 100%; /* Full width */
  max-width: 1200px; /* Adjust width as needed */
  max-height: 90vh; /* Define the scrollable area's height */
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 20px;
  box-sizing: border-box;
  background: #fff; /* Optional: Set background */
  border: 1px solid #ccc; /* Optional: Add border for visibility */
  border-radius: 8px; /* Optional: Rounded corners */
}

.sco-thanks-container{
  border-radius: 20px;
  background-color: #F1E6FE;
  margin: 10px 100px;
}
.sco-thanks-sub-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3vh 7vh 6vh 7vh;
}
.sco-icon-container{
  margin-bottom: 1vh;
  margin-top: 2vh;
}
.sco-thank-message{
  font-size: 28px;
  font-weight: 700;
  color: #8737E1;
  margin-bottom: 10px;
}

.sco-thank-sub-message{
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 3vh;
}
.sco-skipping-info{
  text-align: center;
  margin-top: 10px;
}
.sco-skipping-info-line1-part{
  font-weight: 700;
}

.sco-skipping-info-line1-pillar{
  font-weight: 700;
  color: #8737E1;
}
.sco-skipping-info-line2-link{
  font-weight: 700;
  color: #8737E1;
  text-decoration: underline;
  cursor: pointer;
}
.sco-circular-progress-section {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  align-self: center;
}
.sco-circular-progress-container{
  border-radius: 14px;
  padding: 5px;
}
.sco-circular-progress-info-container{
  text-align: center;
}
.sco-circular-progress-info-pillarname {
  font-weight: 700;
  font-size: 10px;
  text-overflow: ellipsis;
  width: 78px;
  white-space: nowrap;
  overflow: hidden;
}

.sco-circular-progress-info-pillarprogress-completed {
  font-size: 28px;
  font-weight: 600;
}

.sco-circular-progress-info-pillarprogress-total {
  font-size: 12px;
  font-weight: 600;
}
.gridfscontainer {
  margin-bottom: 3vh;
}
</style>