<script>
import Navbar from '@/components/Navbar.vue';
import TopStrip from "@/components/TopStrip.vue";
import Survey from "@/components/Survey/Survey.vue";

export default {
  components: {
    Navbar,
    TopStrip,
    Survey
  },
  data(){
    return {
      navMenuItems: [],
      selectedNavItem: null,
      progressPercent: 0,
    }
  },
  methods: {
    setNavMenuItems(items = []){
      this.navMenuItems = items;
    },
    handleNavItemSelection(item){
      this.selectedNavItem = item;
    },
    handleProgressPercent(newVal){
      this.progressPercent = newVal
    }
  }
};
</script>

<template>
  <div class="flex">
    <nav>
      <Navbar :menu-options="navMenuItems" :on-nav-item-selection="handleNavItemSelection" :progressPercent="progressPercent"/>
    </nav>
    <div class="dfl-right-section">
      <div>
        <TopStrip :progressPercent="progressPercent"/>
      </div>
      <div class="dfl-router-view">
        <Survey :set-nav-menu-items="setNavMenuItems" :selectedNavItem="selectedNavItem" :handleProgressPercent="handleProgressPercent"/>
      </div>
    </div>
  </div>
</template>


<style scoped>
.dfl-right-section {
  margin-left: 289px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}

.dfl-router-view {
  padding: 20px;
}
</style>
